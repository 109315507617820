import { FC } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField, TextFieldProps, MenuItem, styled } from "@mui/material";

type IRHFTextField = {
  name: string;
  list: any;
  ID?: string;
} & TextFieldProps;

type TItem = { [key: string]: any };

const RHFTextField: FC<IRHFTextField> = ({ name, list, ID, ...other }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <StyledInput
            select
            fullWidth
            size="small"
            {...field}
            error={!!error}
            helperText={error?.message}
            {...other}
          >
            {list.map((item: TItem, index: number) => {
              return (
                <MenuItem key={index} value={ID ? item[ID] : item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </StyledInput>
        );
      }}
    />
  );
};
const StyledInput = styled(TextField)(
  ({ error }) => `
 && { 
    
    border: 1px solid #ebebeb;
    border-radius: 4px; 
    width: 100%;
    clear: both;
    display: block; 
    input {  
        padding: 10px;

    } 
  .MuiInputBase-root.Mui-focused.MuiOutlinedInput-notchedOutline {
            border-color: transparent;
    } 
 }
`
);
export default RHFTextField;
