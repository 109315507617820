import React, { FC } from "react";
import { IMaskInput } from 'react-imask';
import { useFormContext, Controller } from "react-hook-form";
import {TextField, TextFieldProps, styled } from "@mui/material";;
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

type IRHFTextField = {
  name: string;
  label?: string;
} & TextFieldProps;

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props : any, ref : any) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const RHFPhoneInput: FC<IRHFTextField> = ({ name, label, ...other }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledInput
          size="small"
          {...field}
          fullWidth
          value={
            typeof field.value === "number" && field.value === 0
              ? ""
              : field.value ?? ""
          }
          placeholder="(123) 456-7890"
          label={label}
          helperText={error?.message}
          InputProps={{
            inputComponent: TextMaskCustom as any,
          }}
          {...other}
          error={!!error}
        >

        </StyledInput>
      )}
    />
  );
};

const StyledInput = styled(TextField)(
  ({ error }) => `
 && {  
    .MuiInputBase-root  {
          border:${error ? " 1px solid red" : " 1px solid #ebebeb"};
    width: 100%;
    border-radius: ${error ? " 0px" : " 0px"}; 

    }
    border-radius: 4px; 
    width: 100%;
    clear: both;
    display: block; 
    input {  
        padding: 10px;

    }
      .MuiInputBase-root {
    &:before { 
      border: none!important; 
    }
    &:after { 
      border: none!important; 
    }
  } 
 }
`
);

export default RHFPhoneInput;
