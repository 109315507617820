import { useQuery, useQueryClient } from "react-query";

import axios from "axios";
import authHeader from "~/repositories/auth-header";
import { API_URL } from "~/config/axios";

export const getBillingList = async () => {
  const res = await axios.get(API_URL + `/CustomerOrder/BilledToList`, {
    headers: authHeader(),
  });
  return res.data;
};

export const useBillingQuery = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["billing"],
    queryFn: () => getBillingList(),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    initialData: () => {
      const billing = queryClient.getQueryData(
        "billing"
      ) as unknown as Array<any>;
      if (billing && billing?.length > 0) return { data: billing };
      else return [];
    },
  });
};

export const useBillingByIDQuery = (
  id: any,
  onHandleSuccess?: (data: any) => void
) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["billing-id", id],
    queryFn: () => getBillingList(),
    enabled: !!id,
    refetchOnWindowFocus: true,
    // onSuccess: (data) => {
    //   onHandleSuccess(data);
    // },
    select: (data) => {
      const billing = data.find((v: any) => v.id === id);
      if (billing) return billing;
      else return {};
    },
    initialData: () => {
      const billingData = queryClient.getQueryData(
        "billing"
      ) as unknown as Array<any>;
      if (billingData) {
        const list = [...billingData];
        const billing = list.find((v: any) => v.id === id);
        if (billing && billing?.length > 0) return { data: billing };
        else return undefined;
      } else return undefined;
    },
  });
};

export const useBillingProfileListQuery = (
  id: any,
) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["billing-list", id],
    queryFn: () => getBillingList(),
    enabled: !!id,
    refetchOnWindowFocus: true,
    select: (data) => {
      if (data) return data;
      else return [];
    },
  });
};