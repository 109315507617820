import { FC, useState, useCallback, useEffect, useContext } from "react";
import Page from "@components/Page";
import CartPaymentForm from "./PayementForm";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from "@mui/lab";
import { 
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Box,
  styled,
  Grid,
  TextField
} from "@mui/material";
import { ShopContext } from "~/contexts/ShopContext";
interface IPropscompanyForm {
  onInvoice: () => void;
  onPayment: (data : any) => void;
  isSubmitting: boolean;
}
const CheckoutPage: FC<IPropscompanyForm> = ({onInvoice,onPayment, isSubmitting}) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [ponumber, setponumber] = useState<any>();
  const { order, onChangeOrder } = useContext(ShopContext);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    const handlePONumber = (data : any): void => {
      onChangeOrder({ ...order, ponumber: data.target.value });
      setponumber(data.target.value)
    }

  return (
    <>
      <Page sx={{ zIndex: 2 }}>
        <Box
          sx={{
            width: "100%",
            pt: "50px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={8} md={4} lg={4} sx={{mb:"30px", ml: "15px"}}>
            <StyledInput
              fullWidth
              id="outlined-controlled"
              hiddenLabel={true}
              size={"medium"}
              name={"ponumber"}
              label={"Purchase Order Number"}
              placeholder={"Purchase Order Number"}
              onChange={(e)=>handlePONumber(e)}
            />
          </Grid>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                color="#000"
                sx={{
                  mb: "20px",
                  lineHeight: "1.5",
                  fontFamily: "Public Sans,sans-serif",
                  fontWeight: "700",
                  display: "block",
                  fontSize: "20px!important",
                }}
              >
                Pay with Card
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CartPaymentForm 
                onPayment={onPayment}
                isSubmitting={isSubmitting}
                ponumber={ponumber}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                color="#000"
                sx={{
                  mb: "20px",
                  lineHeight: "1.5",
                  fontFamily: "Public Sans,sans-serif",
                  fontWeight: "700",
                  display: "block",
                  fontSize: "20px!important",
                }}
              >
                Pay by Invoice
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={6} md={6} lg={6} sx={{m: "auto"}}>
                <CheckoutButton
                  type="submit"
                  sx={{ p: 1, fontSize: "24px", mt: "10px" }}
                  loading={isSubmitting}
                  onClick={()=>onInvoice()}
                >
                  Create Order
                </CheckoutButton>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Page>
    </>
  );
};

const CheckoutButton = styled(LoadingButton)(
  () => `
    background: #1CB7EC;
    border-radius: 0;
    font-size: 18px !important;
    font-weight: 400;
    height: 44px;
    line-height: 22px;
    padding: 0;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    gap: 5px;

    :hover {
      background: #5569ff;
      color: #FFF;
    }

`
);

const StyledInput = styled(TextField)(
  ({ error }) => `
 && { 
    .MuiInputBase-root  {
        border: 1px solid #ebebeb;
            border-radius: 0px; 

    } 

    border-radius: 4px; 
    width: 100%;
    clear: both;
    display: block; 
    input {  
        padding: 10px;

    }
 
    label {
            font-size: 15px;
            top: -3px;
    }
    .MuiInputLabel-shrink {
      top: -5px;
      left: -10px;
    }
    .MuiOutlinedInput-notchedOutline{
      border: 0;
    }
 }
`
);

export default CheckoutPage;
