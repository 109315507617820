import { useQuery, useQueryClient } from "react-query";

import axios from "axios";
import authHeader from "~/repositories/auth-header";
import { API_URL } from "~/config/axios";


const Get = async (id: string) => {
    const res = await axios.get(API_URL + `/Customer/${id}`, {
      headers: authHeader(),
    });
    return res.data;
};

const searchPatientParam = async ({
  page,
  limit,
  firstName,
  lastName,
  dob,
}: {
  page: number;
  limit: number;
  firstName?: string;
  lastName?: string;
  dob?: Date;
}) => {
  let URL = API_URL + `/CustomerContact/searchpatientParam?`;
  if (firstName) {
    URL = URL.concat(`_FirstName=${firstName}&`);
  }
  if (lastName) {
    URL = URL.concat(`_LastName=${lastName}&`);
  }
  if (dob) {
    URL = URL.concat(`dob=${dob}&`);
  }
  const res = await axios.post(
    URL,
    {
      pageNumber: page + 1,
      pageSize: limit,
    },
    { headers: authHeader() }
  );
  return res.data
};

const searchShippingParam = async ({
  page,
  limit,
  name,
}: {
  page: number;
  limit: number;
  name?: string;
}) => {
  let URL = API_URL + `/CustomerContact/searchshippingParam?`;
  if (name) {
    URL = URL.concat(`_name=${name}&`);
  }
  const res = await axios.post(
    URL,
    {
      pageNumber: page + 1,
      pageSize: limit,
    },
    { headers: authHeader() }
  );
  return res.data
};

export const useCustomerQuery = (id: string) => {
    const queryClient = useQueryClient();
    return useQuery({
      queryKey: ["cusomter"],
      queryFn: () => Get(id),
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      initialData: () => {
        const cusomter = queryClient.getQueryData(
          "cusomter"
        ) as unknown as Array<any>;
        if (cusomter) return { data: cusomter };
        else return [];
      },
    });
  };

  export const usePatientSearchQuery = (page: number, limit: number, firstName?: string, lastName?: string, dob?: Date) => {
    const queryClient = useQueryClient();
    return useQuery({
      queryKey: ["pateint-search"],
      queryFn: () => searchPatientParam({page, limit, firstName, lastName, dob}),
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      select: data => {
        return {
          patients: data.data,
          totalRows: data.total,
        };
      },
      initialData: () => {
        const pateint_search = queryClient.getQueryData(
          "pateint_search"
        ) as unknown as Array<any>;
        if (pateint_search) return { data: pateint_search };
        else return undefined;
      },
    });
  };

  export const useShippingSearchQuery = (page: number, limit: number, name?: string) => {
    const queryClient = useQueryClient();
    return useQuery({
      queryKey: ["shipping-search"],
      queryFn: () => searchShippingParam({page, limit, name}),
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      select: data => {
        return {
          shipping: data.data,
          totalRows: data.total,
        };
      },
      initialData: () => {
        const shipping_search = queryClient.getQueryData(
          "shipping_search"
        ) as unknown as Array<any>;
        if (shipping_search) return { data: shipping_search };
        else return undefined;
      },
    });
  };