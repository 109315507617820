import { useQuery, useQueryClient } from "react-query";
import {
  GetAllProduct as getAllProducts,
  getAllProductListForCustomer,
} from "~/repositories/productPricing";

export const useProductsQuery = (user: any, search: any) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["product", user?.customerid, search],
    queryFn: () => getAllProducts(user?.customerid, search),
    enabled: !!user?.customerid,
    refetchOnWindowFocus: false,
    initialData: () => {
      const products = queryClient.getQueryData("product");
      if (products) return { data: products };
      else return undefined;
    },
  });
};

export const useAllProductsProductsQuery = (user: any, search: any) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["allProducts", user?.customerid, search],
    queryFn: () => getAllProductListForCustomer(user?.customerid, search),
    enabled: !!user?.customerid,
    refetchOnWindowFocus: false,
    initialData: () => {
      const products = queryClient.getQueryData("allProducts");
      if (products) return { data: products };
      else return undefined;
    },
  });
};

export const useAllProductsProductsForCartQuery = (user: any, search: any) => {
  search = undefined
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["allProductsForCart", user?.customerid, search],
    queryFn: () => getAllProductListForCustomer(user?.customerid, search),
    enabled: !!user?.customerid,
    refetchOnWindowFocus: false,
    initialData: () => {
      const products = queryClient.getQueryData("allProductsForCart");
      if (products) return { data: products };
      else return undefined;
    },
  });
};
