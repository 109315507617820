import { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface IResponsiveDialog {
  handleClose?: () => void;
  handleAction: (id: any) => void;
  open: boolean;
  title: string;
  contentText: string;
}

const ResponsiveDialog: FC<IResponsiveDialog> = ({
  handleClose,
  handleAction,
  open,
  title,
  contentText,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <strong>{title}</strong>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleAction} autoFocus sx={title=="Delete Item" ? {borderColor: "red", color: "red"} : {}}>
            Confirm
          </Button>
          <Button variant={"outlined"} autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResponsiveDialog;
