import React, { useState, forwardRef } from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { AlertColor } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Taost({
  handleCloseSnack,
  message,
  open,
  severity,
  autoHideDuration,
}: {
  handleCloseSnack: () => void;
  message: string;
  open: boolean;
  severity: AlertColor;
  autoHideDuration?: number;
}) {
  const [state, setState] = React.useState<SnackbarOrigin>({
    vertical: "bottom",
    horizontal: "right",
  });

  const { vertical, horizontal } = state;
  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleCloseSnack}
      key={vertical + horizontal}
      sx={{ px: 1, py: 2 }}
      message={message}
    >
      <Alert sx={{ color: "#FFF" }} severity={severity}>
        {/* <CheckCircleIcon color="success" /> */}
        {message}
      </Alert>
    </Snackbar>
  );
}
