import { FC, useCallback, useState} from "react";

import { Typography, Divider, TextField, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
export interface State extends SnackbarOrigin {
  openToast: boolean;
}

interface IPropscompanyForm {
  defaultProduct: any[];
}

export const ReviewOrder: FC<IPropscompanyForm> = ({ defaultProduct }) => {

  const [showImageList, setImageList] = useState<any[]>([])

  const onClick = useCallback((item : any) =>{
    if(showImageList.includes(item)){
      setImageList((current) => current.filter((i) => i != item))
    }else{
      setImageList([...showImageList, item])
    }
  },[showImageList])

  return (
    <ProductGrid>
      <GridHeader>
        <Typography
          sx={{
            lineHeight: "1.5",
            fontFamily: "Public Sans,sans-serif",
            fontWeight: "700",
            display: "block",
            fontSize: "20px",
            width: "50%",
            color: "#FFF",
          }}
        >
          Product
        </Typography>
        <Typography
          sx={{
            lineHeight: "1.5",
            fontFamily: "Public Sans,sans-serif",
            fontWeight: "700",
            display: "block",
            fontSize: "20px",
            width: "10%",
            color: "#FFF",
          }}
        >
          Type
        </Typography>
        <Typography
          sx={{
            lineHeight: "1.5",
            fontFamily: "Public Sans,sans-serif",
            fontWeight: "700",
            display: "block",
            fontSize: "20px",
            width: "10%",
            color: "#FFF",
          }}
        >
          Price
        </Typography>
        <Typography
          sx={{
            lineHeight: "1.5",
            fontFamily: "Public Sans,sans-serif",
            fontWeight: "700",
            display: "block",
            fontSize: "20px",
            width: "15%",
            color: "#FFF",
          }}
          align="center"
        >
          Quantity
        </Typography>
        <Typography
          sx={{
            lineHeight: "1.5",
            fontFamily: "Public Sans,sans-serif",
            fontWeight: "700",
            display: "block",
            fontSize: "20px",
            width: "15%",
            color: "#FFF",
          }}
        >
          Subtotal
        </Typography>
      </GridHeader>
      {defaultProduct?.map((item: any, index: number) => {
        return (
          <Box display="flex" flexDirection="column" key={index}>
            <Box sx={showImageList.includes(item)? {height: "200px", display: "flex", gap: "10px", padding: "20px"} : { display: "flex", gap: "10px", padding: "20px"}} >
              <Box sx={{display: "flex", width:"50%"}}>
                {(item.productimgurl && showImageList.includes(item)) && (
                  <StyledProdactImg>
                    <Box>
                      <Tooltip title={"Hide Image"}>
                        <IconButton
                          sx={{
                            //border: "1px solid #000",
                            color: "#000",
                            padding: "10px",
                            borderRadius: 5,
                            top: -5,
                            right: -5,
                            position: "absolute",
                            zIndex: 5,
                            m:0
                          }}
                          onClick={() => onClick(item)}
                        >
                          <HideImageOutlinedIcon/>
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      component="img"
                      src={
                        item.productimgurl || "/static/image/Pas-dimage-disponible.jpg"
                      }
                      sx={{
                        position: "absolute",
                        height: "100%",
                        transform: "translate(-50%, -50%)",
                        top: "50%",
                        left: "50%",
                        objectFit: "contain",
                        width: "100%",
                      }}
                    />
                  </StyledProdactImg>
                )}
                {(!showImageList.includes(item) && item.productimgurl) && (
                  <>
                    <Tooltip title={"Show Image"}>
                      <IconButton
                        sx={{
                          //border: "1px solid #000",
                          color: "#000",
                          padding: "10px",
                          borderRadius: 5,
                        }}
                        onClick={() => onClick(item)}
                      >
                        <ImageOutlinedIcon/>
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                <StyledProductDescription sx={showImageList.includes(item)? {width:"60%"} : {width:"100%"}}>
                  <StyledOrderTitle
                    sx={{
                      lineHeight: "1.5",
                      fontFamily: "Public Sans,sans-serif",
                      fontWeight: "700",
                      display: "block",
                      fontSize: "16px",
                      textAlign: "start",
                    }}
                  >
                    {item.productname}
                  </StyledOrderTitle>
                </StyledProductDescription>
              </Box>
              <Box sx={{display: "flex", width:"10%"}}>
                <StyledFooterDescription>
                  <Price>
                    {item.producttypewithval}
                  </Price>
                </StyledFooterDescription>
              </Box>
              <Box sx={{display: "flex", width:"40%"}}>
                <StyledFooterDescription>
                  <StyledPriceProduct sx={{width: "100px"}}>
                    <Price>${item.customerprice}</Price>
                  </StyledPriceProduct>
                  <QuantityInput
                    sx={{width: "70px"}}
                    fullWidth
                    type="number"
                    disabled
                    InputProps={{ inputProps: { min: 1 } }}
                    value={item.quantity}
                  />
                  <StyledPriceProduct sx={{width: "100px",ml:"15px"}}>
                    <Price>${(item.customerprice * parseInt(item.quantity)).toFixed(2)}</Price>
                  </StyledPriceProduct>
                </StyledFooterDescription>
              </Box>
            </Box>
            <Divider />
          </Box>
        );
      })}
    </ProductGrid>
  );
};
  

const QuantityInput = styled(TextField)(
  ({ theme }) => ` 
  width: 25%;

  input {
    text-align: center;
    margin-left: 10px;
  }

  & .MuiOutlinedInput-root {
    width: 100%;
    height: 25px;
  }

  fieldset {
    border: 1px solid #dfdfdf !important;
    border-radius: 0 !important;
  }
`
);
const StyledProductCart = styled(Box)(
  () => `
    display: flex;
    gap: 10px;
    height: 200px;
    padding: 20px;
};
`
);

const Price = styled(Typography)(
  () => `  
  font-style: normal;
  margin-left:6px;
  font-weight: 700!important;
  font-size: 24!important;
  line-height: 30px!important;
  color: #6B6B6B;

`
);
const StyledPriceProduct = styled(Typography)(
  () => `
    font-style: normal;
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 30px!important; 
    display: flex;
    justify-content: space-between; 
    margin-right: 6px;
    color: #44A2DF!important;
`
);
const StyledOrderTitle = styled(Typography)(
  () => `  
    display: flex;
    justify-content: space-between;
    color: #000!important;
`
);

const StyledProductDescription = styled(Box)(
  () => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 0 10px;
`
);

const StyledFooterDescription = styled(Box)(
  () => `
     display:flex;
     justify-content: space-around;
     align-items: center;
     gap: 10px;
`
);

const ProductGrid = styled(Box)(
  () => `
  background: #FFF;
  width: 900px;
  display: flex;
  flex-direction: column;
`
);

const GridHeader = styled(Box)(
  () => `
 display: flex;
 background: #1CB7EC;
 padding: 10px;
 `
);

const StyledProdactImg = styled(Box)(
  () => `
    width: 25%;
    height: 100%;
    position: relative;
    border: 1px solid #dfdfdf;
`
);
const StyledDescription = styled(Box)(
  () => `
     font-style: normal;
    font-weight: 400!important;
    font-size: 13px!important;
    line-height: 30px!important;  
    margin-right: 6px;
    color: #6B6B6B!important;
`
);
