// material
import { Grid, Pagination, useTheme } from "@mui/material";
import ShopProductCard from "~/components/ProductCard";
import React, { FC, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// ----------------------------------------------------------------------

interface ProductProps {
  products: Array<any>;
  cart: Array<any>;
}

const ProductList: FC<ProductProps> = ({ products, cart }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 32;
  const totalPages = Math.ceil(products.length / rowsPerPage);

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    const newPath = `/product/${page}`
    navigate(newPath)
    setCurrentPage(page);
  };
  const theme = useTheme();

  const paginatedData = products.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  React.useEffect(()=>{
    if(location.pathname.split("/").length >= 3){
      setCurrentPage(Number.parseInt(location.pathname.split("/")[2]))
      if(location.pathname.split("/").length >= 4){
        const element = document.getElementById(location.pathname.split("/")[3]);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' , block: 'center'});
        }
      }else{
        document.getElementById("root")?.scrollIntoView();
      }
    }else{
      navigate("/product/1")
      setCurrentPage(1)
    }

  },[location,paginatedData])

  return (
    <Grid
      // sx={{
      //   overflowAnchor: "none",
      // }}
      container
      spacing={0.5}
      id={"top"}
    >
      {paginatedData?.map((product) => (
        <Grid
          key={product.sku}
          sx={{
            [theme.breakpoints.down(820)]: {
              maxWidth: "100%",
              flexBasis: "100%",
            },
          }}
          id={`${product.productdetails[0].productid}`}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
        >
          <ShopProductCard 
            product={product} 
            cart={cart}
          />
        </Grid>
      ))}
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "20px",
        }}
        item
        xs={12}
        sm={12}
        md={12}
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};
export default ProductList;
