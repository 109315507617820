import React from "react";
import { Routes, Route } from "react-router-dom";
import ChangePasswordPage from "~/pages/changePassword";
import OrderPage from "~/pages/OrderPage/OrderList";
import { withFallback } from "./withFallback";
import ProductPage from "~/pages/ProductPage/index";
const MainLayout = withFallback(
  React.lazy(() => import("~/layouts/LandingPageLAyout"))
);
const RegisterLayouts = withFallback(
  React.lazy(() => import("~/layouts/RegisterLayouts"))
);

const CheckoutPage = withFallback(
  React.lazy(() => import("~/pages/CheckoutPage/index"))
);
const LandingLoginPage = withFallback(
  React.lazy(() => import("~/pages/LandingLoginPage"))
);

const LoginPage = withFallback(React.lazy(() => import("~/pages/Login/index")));
// const ProductPage = withFallback(
//   React.lazy(() => import("~/pages/ProductPage/index"))
// );
const OrderDetailPage = withFallback(
  React.lazy(() => import("~/pages/OrderDetails"))
);
const ForgotPage = withFallback(
  React.lazy(() => import("~/pages/ForgotPassword"))
);
const ProductDetailsPage = withFallback(
  React.lazy(() => import("~/pages/ProductPage/ProductDetails"))
);

const InvoicePage = withFallback(
  React.lazy(() => import("~/pages/OrderPage/InvoiceList"))
);
const ProfilePage = withFallback(
  React.lazy(() => import("~/pages/ProfilePage"))
);

const RenderRouter: React.FC = () => {
  return (
    <Routes>
      <Route element={<RegisterLayouts />}>
        <Route path="/login" element={<LoginPage />} />
      </Route>
      <Route element={<RegisterLayouts />}>
        <Route path="/forgotPassword" element={<ForgotPage />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/" element={<LandingLoginPage />} />
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route path="/change-password" element={<ChangePasswordPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/product/:pagenumber" element={<ProductPage />} />
        <Route path="/product/:pagenumber/:itemid" element={<ProductPage />} />
        <Route path="/Checkout" element={<CheckoutPage />} />
        <Route path="/invoices" element={<InvoicePage />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/invoices/payinvoice" element={<InvoicePage />} />
        <Route path="/order/:id" element={<OrderDetailPage />} />
        <Route path="/order/:id/:subid" element={<OrderDetailPage />} />
        <Route path="/profile" element={<ProfilePage/>}/>
        <Route path="/profile/payment" element={<ProfilePage/>}/>
        {/* <Route path="/about-us" element={<AboutUsPage />} /> */}
        <Route
          path="/product/product-details/:id"
          element={<ProductDetailsPage />}
        />
      </Route>
    </Routes>
  );
};

export default RenderRouter;
