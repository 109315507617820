/**
 * 
 * Product page, displays all the products for a user
 * 
 */
import { useCallback, useContext, useRef, useState } from "react";
// material
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import ShopFilterSidebar from "~/components/FilterSideBar";
import Page from "~/layouts/PageWrapper";
import { If, Then, Else } from "react-if";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { ShopContext } from "~/contexts/ShopContext";
import { SearchContext } from "~/contexts/SearchContext";
import { Box } from "@mui/system";
import { AuthContext } from "~/contexts/authContext";
import * as React from "react";
import ProductGrid from "./ProductGrid";
import { getCustomerCart } from "~/repositories/customerOrder.service";
import ProductList from "./ProductList";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import Breadcrumb from "~/components/Breadcrumbs";
import {
  useProductsQuery,
  useAllProductsProductsForCartQuery,
} from "~/reactQueryHooks/useQueryProducts";
type IDefaultValues = Record<string, any>;

// ----------------------------------------------------------------------

const SuspenseloderProduct = styled(CircularProgress)(
  () => `
&& {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 70px;
  height: 70px;
  }
`
);

const Title = styled(Typography)(
  () => ` 
    font-style: normal;
    font-weight: 600!important;
    font-size: 30px!important;
    line-height: 82px!important; 
    color: #44A2DF; 
`
);

enum DisplayType {
  Grid = "grid",
  Cards = "cards",
}

export const DEFAULT_PRODUCT_VALUES: IDefaultValues = {
  productid: null,
};

export default function EcommerceShop() {
  const [filtredProduct, setFilteredProduct] = useState<Array<any>>([]);
  const [displayType, setDisplayType] = React.useState<DisplayType>(
    DisplayType.Cards
  );

  const theme = useTheme();

  const { onChangeData, products } = useContext(ShopContext);
  const { search } = React.useContext(SearchContext);
  const { user } = useContext(AuthContext);

  const GetCustomerCartData = useRef(getCustomerCart);

  const { error: errorAllProduct, data: allProducts } =
    useAllProductsProductsForCartQuery(user, search);

  const { isLoading, error, data: product, refetch } = useProductsQuery(user, search);

  const getCartProducts = useCallback(async () => {
    await GetCustomerCartData.current().then(
      (response: any) => {
        const filtred = allProducts?.filter((item1: any) =>
          response.data.some(
            (item2: any) => item2.productsubid === item1.productsubid
          )
        );
        const filtredQuantity = filtred?.map((item: any) => {
          const value = response.data?.filter(
            (element: any) => item.productsubid === element.productsubid
          );
          return {
            ...item,
            quantity: value[0].qty,
          };
        });
        onChangeData(filtredQuantity);
      },
      (error: any) => {
        console.log(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProducts]);

  React.useEffect(() => {
    getCartProducts();
    refetch()
  }, [getCartProducts]);
  // const { error: errorCartProduct, data } = useQuery({
  //   queryKey: ["filtred", allProducts],
  //   queryFn: () => getCartProducts(),
  //   enabled: allProducts && allProducts?.length > 0,
  //   refetchOnWindowFocus: "always",
  // });

  // const { error: errorCartProduct, data } = useQuery("filtred", () =>
  //   getCartProducts()
  // );

  const handleSelectOneItem = useCallback(
    (Item: any, quantity: number): void => {
      const listProduct = filtredProduct && [...filtredProduct];
      listProduct?.map((p) => {
        if (p.sku === Item.sku) {
          p.quantity = quantity > 0 ? quantity : 1;
          return p;
        } else return p;
      });
      setFilteredProduct(listProduct);
    },
    [filtredProduct]
  );

  const handleFilter = useCallback((newArray: any) => {
    setFilteredProduct(newArray);
  }, []);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: DisplayType
  ) => {
    setDisplayType(newAlignment);
  };

  return (
    <Page title="Dashboard: Products">
      <Container
        sx={{
          paddingBottom: "20px",
          [theme.breakpoints.down("lg")]: {
            paddingLeft: "10px",
            paddingRight: "10px",
          },
        }}
        maxWidth={false}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <Breadcrumb /> */}
          <Container sx={{ position: "relative" }} maxWidth="xl">
            <Box>
              <Title gutterBottom variant="h5">
                Home
              </Title>
            </Box>
          </Container>
          {/* <Typography fontWeight="400" fontSize="14px" letterSpacing="2px">
            Home / Products
          </Typography> */}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              mb: 1,
            }}
          >
            <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
              {/* <ToggleButtonGroup
                // color="primary"
                value={displayType}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                sx={{
                  width: "120px",
                }}
              >
                <ToggleButton
                  sx={{ width: "50%", padding: "3px" }}
                  value={DisplayType.Grid}
                  disabled={
                    Boolean(!filtredProduct) ||
                    Boolean(filtredProduct?.length === 0)
                  }
                >
                  <ViewListIcon />
                </ToggleButton>
                <ToggleButton
                  sx={{ width: "50%", padding: "3px" }}
                  value={DisplayType.Cards}
                  disabled={
                    Boolean(!filtredProduct) ||
                    Boolean(filtredProduct?.length === 0)
                  }
                >
                  <ViewModuleIcon />
                </ToggleButton>
              </ToggleButtonGroup> */}
            </Stack>
          </Stack>
        </Box>

        <Grid container columnSpacing={"30px"}>
          <Grid item xs={12} sm={2.5} md={2.5} lg={2.5}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflowY: "hidden",
                // [theme.breakpoints.up("lg")]: {
                //   width: "30%",
                // },
              }}
            >
              <ShopFilterSidebar
                products={product}
                filtredProduct={filtredProduct}
                onFilter={handleFilter}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={9} lg={9}>
            <If condition={displayType === DisplayType.Cards}>
              <Then>
                <If
                  condition={
                    Boolean(!filtredProduct) ||
                    Boolean(filtredProduct?.length === 0)
                  }
                >
                  <Then>
                    {isLoading ? (
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {" "}
                        <SuspenseloderProduct
                          size={64}
                          disableShrink
                          thickness={3}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography>There Is No Product</Typography>
                      </Box>
                    )}
                  </Then>
                  <Else>
                    <ProductList cart={products} products={filtredProduct} />
                  </Else>
                </If>
              </Then>
              <Else>
                <ProductGrid
                  allProducts={filtredProduct}
                  selectedProducts={products?.map((item: any) => item.sku)}
                  onSelect={handleSelectOneItem}
                />
              </Else>
            </If>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
