import React, { FC, ReactNode, useContext, useState, useCallback } from "react";
import { Typography, IconButton, Divider, TextField, Tooltip } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import { ShopContext } from "~/contexts/ShopContext";
import {
  deleteCustomerCartProduct,
  UpdateCustomerCart,
} from "~/repositories/customerOrder.service";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import Modal from "@components/Modal/NotificationModal";

export interface State extends SnackbarOrigin {
  openToast: boolean;
}

const IconCustomButton = ({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: ReactNode;
}) => (
  <IconButton
    color="primary"
    onClick={onClick}
    sx={{ color: "#6B6B6B!important" }}
  >
    {children}
  </IconButton>
);

export const StyledCart: FC<any> = ({ disabled }) => {
  const { products, onChangeData } = useContext(ShopContext);
  const [showImageList, setImageList] = useState<any[]>([])

  const [qtyErrorMsg, setQtyErrorMsg] = React.useState<any>(undefined);
  const [openBackOrder, setOpenBackOrder] = React.useState<boolean>(false);
  
  const [selectedItem, setSelectedItem] = React.useState<any>();

  const handleRemove = React.useCallback(
    async (Item: any) => {
      const filtred = products?.filter(
        (element: any) => element.productsubid !== Item.productsubid
      );
      await deleteCustomerCartProduct({
        productid: Item.productid,
        productsubid: Item.productsubid,
        qty: 1,
      });
      onChangeData(filtred);
    },
    [products]
  );

  const checkStockQty = useCallback((id : any, quantity : number)=>{
    let error = false
    const listProduct = products && [...products];
    setSelectedItem({id: id, quantity: quantity})
    const productType = listProduct.find((item:any)=>{return item.productsubid === id})
     if(productType && quantity) {
        if(productType.availableqty_type < quantity && !productType.isnoninventory){
          setQtyErrorMsg(
            <Typography>
              Available <b>{productType.producttypewithval}</b> is <b>{productType.availableqty_type}</b> and the requested for <b>{quantity}</b>. If you continue with this order then it will be processed as a backorder, or you can click cancel and change the quantity to be the same as available quantity to be processed as a normal order. Would you like to confirm?
            </Typography>
          )
          error = true
        }else if(listProduct && !productType.isnoninventory){
          const productList = listProduct.filter((item:any)=>{return (item.productid === productType.productid && item.productsubid !== productType.productsubid)})
          if(productList.length > 0){
            const availableqty = productType.availableqty;
            let requestedqty = (productType.formulaqty * quantity);
            productList.forEach((item : any)=> {requestedqty += (item.formulaqty * item.quantity)})
            if(requestedqty > availableqty){
              setQtyErrorMsg(
                <Typography>
                    Available <b>{productType.productname}</b> is <b>{availableqty}</b> and the requested amount, including items in your cart, is <b>{requestedqty}</b>. If you continue with this order then it will be processed as a backorder, or you can click cancel and change the quantity to be the same as available quantity to be processed as a normal order. Would you like to confirm?
                </Typography>
                )
              error = true
            }
          }
        } 
        if(error){
          setOpenBackOrder(true)
        }else{
          handleQuantity(id, quantity)
        }
      }
    },[products])

  const handleQuantity = React.useCallback(
    (id: any, quantity: number) => {
      const listProduct = products && [...products];
      listProduct?.map(async (p) => {
        if (p.productsubid === id) {
          p.quantity = quantity > 0 ? quantity : 1;
          await UpdateCustomerCart({
            productid: p.productid,
            productsubid: p.productsubid,
            qty: quantity > 0 ? quantity : 1,
          });
          return p;
        } else return p;
      });
      onChangeData(listProduct);
    },
    [products]
  );

  const onClick = useCallback((item : any) =>{
    if(showImageList.includes(item)){
      setImageList((current) => current.filter((i) => i != item))
    }else{
      setImageList([...showImageList, item])
    }
  },[showImageList])

  return (
    <ProductGrid>
      <Modal
        open={openBackOrder}
        handleClose={() => {
          setOpenBackOrder(false);
          setQtyErrorMsg(undefined)
          setSelectedItem(undefined)
        }}
        handleAction={()=>{
          if(selectedItem)
            handleQuantity(selectedItem.id,selectedItem.quantity)
          setOpenBackOrder(false);
          setSelectedItem(undefined)
        }}
        title={"Back Order Warning"}
        contentText={qtyErrorMsg? qtyErrorMsg : ""}
      />
      <GridHeader>
        <Typography
          sx={{
            lineHeight: "1.5",
            fontFamily: "Public Sans,sans-serif",
            fontWeight: "700",
            display: "block",
            fontSize: "20px",
            width: "50%",
            color: "#FFF",
          }}
        >
          Product
        </Typography>
        <Typography
          sx={{
            lineHeight: "1.5",
            fontFamily: "Public Sans,sans-serif",
            fontWeight: "700",
            display: "block",
            fontSize: "20px",
            width: "10%",
            color: "#FFF",
          }}
        >
          Type
        </Typography>
        <Typography
          sx={{
            lineHeight: "1.5",
            fontFamily: "Public Sans,sans-serif",
            fontWeight: "700",
            display: "block",
            fontSize: "20px",
            width: "10%",
            color: "#FFF",
          }}
        >
          Price
        </Typography>
        <Typography
          sx={{
            lineHeight: "1.5",
            fontFamily: "Public Sans,sans-serif",
            fontWeight: "700",
            display: "block",
            fontSize: "20px",
            width: "15%",
            color: "#FFF",
          }}
          align="center"
        >
          Quantity
        </Typography>
        <Typography
          sx={{
            lineHeight: "1.5",
            fontFamily: "Public Sans,sans-serif",
            fontWeight: "700",
            display: "block",
            fontSize: "20px",
            width: "15%",
            color: "#FFF",
          }}
        >
          Subtotal
        </Typography>
      </GridHeader>
      {products?.map((item: any, index: number) => {
        return (
          <Box display="flex" flexDirection="column" key={index}>
            <Box sx={showImageList.includes(item)? {height: "200px", display: "flex", gap: "10px", padding: "20px"} : { display: "flex", gap: "10px", padding: "20px"}} >
              <Box sx={{display: "flex", width:"50%"}}>
                {(item.productimgurl && showImageList.includes(item)) && (
                  <StyledProdactImg>
                    <Box>
                      <Tooltip title={"Hide Image"}>
                        <IconButton
                          sx={{
                            //border: "1px solid #000",
                            color: "#000",
                            padding: "10px",
                            borderRadius: 5,
                            top: -5,
                            right: -5,
                            position: "absolute",
                            zIndex: 5,
                            m:0
                          }}
                          onClick={() => onClick(item)}
                        >
                          <HideImageOutlinedIcon/>
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      component="img"
                      src={
                        item.productimgurl || "/static/image/Pas-dimage-disponible.jpg"
                      }
                      sx={{
                        position: "absolute",
                        height: "100%",
                        transform: "translate(-50%, -50%)",
                        top: "50%",
                        left: "50%",
                        objectFit: "contain",
                        width: "100%",
                      }}
                    />
                  </StyledProdactImg>
                )}
                {(!showImageList.includes(item) && item.productimgurl )&& (
                  <>
                    <Tooltip title={"Show Image"}>
                      <IconButton
                        sx={{
                          //border: "1px solid #000",
                          color: "#000",
                          padding: "10px",
                          borderRadius: 5,
                        }}
                        onClick={() => onClick(item)}
                      >
                        <ImageOutlinedIcon/>
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                <StyledProductDescription sx={showImageList.includes(item)? {width:"60%"} : {width:"100%"}}>
                  <StyledOrderTitle
                    sx={{
                      lineHeight: "1.5",
                      fontFamily: "Public Sans,sans-serif",
                      fontWeight: "700",
                      display: "block",
                      fontSize: "16px",
                      textAlign: "start",
                    }}
                  >
                    {item.productname}
                  </StyledOrderTitle>
                </StyledProductDescription>
              </Box>
              <Box sx={{display: "flex", width:"10%"}}>
                <StyledFooterDescription>
                  <Price>
                    {item.producttypewithval}
                  </Price>
                </StyledFooterDescription>
              </Box>
              <Box sx={{display: "flex", width:"40%"}}>
                <StyledFooterDescription>
                  <StyledPriceProduct sx={{width: "80px"}}>
                    <Price>${item.customerprice}</Price>
                  </StyledPriceProduct>
                  <Box sx={{display:"flex", gap: "10px", width: "100px"}}>
                    <IconButton
                      sx={{
                        // border: "1px solid #000",
                        color: "#000",
                        padding: "5px",
                        borderRadius: 0,
                      }}
                      onClick={() => {
                        if (item.quantity > 1) {
                          checkStockQty(item.productsubid, item.quantity - 1);
                        }
                      }}
                      disabled={item.quantity === 1}
                      value={item.quantity}
                    >
                      <RemoveIcon fontSize="small" />
                    </IconButton>
                    <Typography
                      sx={{
                        fontFamily: "proxima_nova_ltsemibold",
                        fontSize: "16px",
                      }}
                    >
                      {item.quantity}
                    </Typography>

                    <IconButton
                      disabled={disabled}
                      sx={{
                        padding: "5px",
                        // border: "1px solid #000",
                        color: "#000",
                        borderRadius: 0,
                      }}
                      onClick={() => {
                        checkStockQty(item.productsubid, item.quantity + 1);
                      }}
                      value={item.quantity}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <StyledPriceProduct sx={{width: "50px"}}>
                    <Price>${(item.customerprice * parseInt(item.quantity)).toFixed(2)}</Price>
                  </StyledPriceProduct>
                  <ButtonDelete>
                  <IconCustomButton>
                    <DeleteForeverIcon
                      onClick={() => handleRemove(item)}
                      sx={{ cursor: "pointer", fill: "#fc5050" }}
                    />
                  </IconCustomButton>
                </ButtonDelete>
                </StyledFooterDescription>
                
              </Box>
            </Box>
            <Divider />
          </Box>
        );
      })}
    </ProductGrid>
  );
}

const QuantityInput = styled(TextField)(
  ({ theme }) => ` 
  width: 25%;

  & .MuiOutlinedInput-root {
    width: 100%;
    height: 25px;
  }

  fieldset {
    border: 1px solid #dfdfdf !important;
    border-radius: 0 !important;
  }
`
);
const StyledProductCart = styled(Box)(
  () => `
    display: flex;
    gap: 25px;
    height: 200px;
    padding: 20px;
};
`
);

const Price = styled(Typography)(
  () => `  
  font-style: normal;
  margin-left:6px;
  font-weight: 700!important;
  font-size: 24!important;
  line-height: 30px!important;
  color: #6B6B6B;

`
);
const StyledPriceProduct = styled(Typography)(
  () => `
font-style: normal;
font-weight: 400!important;
font-size: 16px!important;
line-height: 30px!important; 
display: flex;
justify-content: space-between; 
margin-right: 6px;
color: #44A2DF!important;
`
);
const StyledOrderTitle = styled(Typography)(
  () => `  
    display: flex;
    justify-content: space-between;
    color: #000!important;
`
);

const StyledProductDescription = styled(Box)(
  () => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 0 10px;
`
);

const StyledFooterDescription = styled(Box)(
  () => `
     display:flex;
    //  justify-content: space-between;
     align-items: center;
     column-gap: 28px;
`
);

const ProductGrid = styled(Box)(
  () => `
  background: #FFF;
  width: 1000px;
  display: flex;
  flex-direction: column;
`
);

const GridHeader = styled(Box)(
  () => `
 display: flex;
 background: #1CB7EC;
 padding: 10px;
 `
);

const ButtonDelete = styled(Box)(
  () => `
 
`
);
const StyledProdactImg = styled(Box)(
  () => `
    width: 25%;
    height: 100%;
    position: relative;
    border: 1px solid #dfdfdf;
`
);
const StyledDescription = styled(Box)(
  () => `
     font-style: normal;
    font-weight: 400!important;
    font-size: 13px!important;
    line-height: 30px!important;  
    margin-right: 6px;
    color: #6B6B6B!important;
`
);
