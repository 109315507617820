import { useQuery, useQueryClient } from "react-query";
import { GetAllState } from "~/repositories/ddllist.service";

export const useStaleQuery = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["product-stale"],
    queryFn: () => GetAllState(),
    refetchOnWindowFocus: false,
    select: (data) => {
      return data?.State.map((s: any, index: number) => ({
        name: s.state,
        id: index,
      }));
    },
    initialData: () => {
      const stale = queryClient.getQueryData(
        "product-stale"
      ) as unknown as Array<any>;
      if (stale && stale?.length > 0) return { data: stale };
      else return undefined;
    },
  });
};
