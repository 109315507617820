/*

This handles shipping/patient and billing address information
Address Book is used to handle selection

We first check if user has patients or is using shipping addresses
check for default addresses and then allow them to set addresses or create addresses as the user needs

*/

import {
  useCallback,
  useState,
  ReactNode,
  useEffect,
  FC,
  useMemo,
  useContext,
  useRef,
} from "react";
import {
  Typography,
  IconButton,
  TextField,
  styled,
  Stack,
  Grid,
} from "@mui/material";


import Box from "@mui/material/Box";
import AddressBook from "./AddressBook";

import { ShopContext } from "~/contexts/ShopContext";
import { AuthContext } from "~/contexts/authContext";

import { useShippingSearchQuery } from "~/reactQueryHooks/useQueryCustomer";
import { useBillingQuery } from "~/reactQueryHooks/useQueryBilling";
import { usePatientSearchQuery } from "~/reactQueryHooks/useQueryCustomer";

export enum AdressType {
  Shipping = "shipping",
  Billing = "billing",
}

export enum FormType {
  Create = "create",
  Edit = "edit",
}

interface IPagination {
  page: number;
  limit: number;
}
export interface ICustomerContactProps {
  customerID?: any;
  isOpen?: boolean;
  onOpenForm?: () => void;
  onCloseForm?: () => void;
  query?: string;
  onHandleSelectedItems?: (items: any) => void;
  selectedID?: number;
  selected?: number;
  update?: boolean;
  handleUpdate?: () => void;
  type?: AdressType;
}

const IconCustomButton = ({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: ReactNode;
}) => (
  <IconButton
    onClick={onClick}
    sx={{
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.25)",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "19px",
      color: "#FFF!important",
      width: "250px",
      borderRadius: "10px",
      height: "50px",
      display: "flex",
      gap: "15px",
      float: "right",
      background: " #12a7da",
      fontSize: "16px",
      borderRdius: "4px",
      padding: "10px 20px",
      ":hover": {
        backgroundColor: "#000",
      },
    }}
  >
    {children}
  </IconButton>
);

const SearchButton = ({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: ReactNode;
}) => (
  <IconButton
    onClick={onClick}
    sx={{
      boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.25)",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "19px",
      color: "#FFF!important",
      width: "100px",
      
      borderRadius: "10px",
      height: "50px",
      display: "flex",
      gap: "15px",
      float: "right",
      background: " #12a7da",
      fontSize: "16px",
      borderRdius: "4px",
      padding: "10px 20px",
      marginRight: "15px",
      marginBottom: "10px",
      ":hover": {
        backgroundColor: "#000",
      },
    }}
  >
    {children}
  </IconButton>
);

IconCustomButton.displayName = "IconCustomButton";
const PatientPage: FC<ICustomerContactProps> = ({ type }) => {
  const { order, onChangeOrder } = useContext(ShopContext);

  const firstTime = useRef(false);
  const { user } = useContext(AuthContext);

  const [pagination, setPagination] = useState<IPagination>({
    page: 0,
    limit: 100000,
  });
  const [nameQuery, setNameQuery] = useState<any>();
  const [isAddressOpen, setAddressOpen] = useState<boolean>(false);
  const [addressType, setAddressType] = useState<number>(0);
  const [selectedShipping, setSelectedShipping] = useState<any>();
  const [selectedBilling, setSelectedBilling] = useState<any>();

  const { isLoading: isLoadingShipping, data: shippingData, refetch: refetchShipping } =
  useShippingSearchQuery(pagination.page, pagination.limit, nameQuery);

  const { isLoading: isLoadingBilling, data: billingList, refetch: refetchBilling } = useBillingQuery();

  const { isLoading: isLoadingPatients, data: patientsData, refetch: refetchPatients } =
  usePatientSearchQuery(pagination.page, pagination.limit, "", "", undefined);

  //Change Order

  const handleSelectShippingAddress = (data: any): void => {
    console.log(data)
    setSelectedShipping(data);
    onChangeOrder({ ...order, shipto_id: data.id, taxpercent: data.salestaxcal });
  };

  const handleSelectBilling = (data: any): void => {
    setSelectedBilling(data);
    onChangeOrder({ ...order, billto_id: data.id });
  };

  const handleSetDefaults = (billing: any, shipping : any): void => {
    setSelectedBilling(billing);
    setSelectedShipping(shipping);
    onChangeOrder({ ...order, billto_id: billing.id, shipto_id: shipping.id, taxpercent: shipping.salestaxcal });
  };

  //Default Address

  const defaultShipping = useMemo(() => {
      if(user?.isallowaddpatient){
        if(patientsData){
          if(order.shipto_id){
            return patientsData.patients.find((i : any) => i.id == order.shipto_id);
          }else{
            return patientsData.patients.find((i : any) => i.isdefault == true);
          }
        }
      }else if(shippingData){
        if(order.shipto_id){
          return shippingData.shipping.find((i : any) => i.id == order.shipto_id);
        }else
          return shippingData.shipping.find((i : any) => i.isdefault == true);
      }
  }, [shippingData, patientsData, user, order]);

  const defaultBilling = useMemo(() => {
    if(billingList?.length > 0){
      if(order.billto_id){
        return billingList.find((i : any) => i.id == order.billto_id);
      }else{
        return billingList.find((i : any) => i.isdefault == true);
      }
    }
  }, [shippingData]);
  
  const handleCloseModal = useCallback(()=>{
    setAddressOpen(false)
  },[])

  const handleOpenModal = useCallback((type : number)=>{
    setAddressType(type)
    setAddressOpen(true)
  },[])

  const handleRefetch = (type : number) =>{
    if(type == 0){
      refetchShipping()
    }else if(type == 1){
      refetchBilling()
    }else{
      refetchPatients()
    }
  }

  useEffect(()=>{
    if(defaultBilling && defaultShipping)
      handleSetDefaults(defaultBilling, defaultShipping)
    else if(defaultBilling)
      handleSelectBilling(defaultBilling)
    else if(defaultShipping)
      handleSelectShippingAddress(defaultShipping)
  },[defaultShipping,defaultBilling])


  useEffect(() => {
    if (!firstTime.current) {
      refetchShipping()
      firstTime.current = true;
    }
  }, [nameQuery]);

  useEffect(()=>{
    refetchShipping()
    refetchBilling()
    refetchPatients()
  },[])

  return (
    <StyledProductCart>
      <AddressBook
        onClose={handleCloseModal}
        addressList={addressType == 0? shippingData?.shipping : addressType == 1? billingList : addressType == 2? patientsData?.patients : []}
        open={isAddressOpen}
        typeIndex={addressType}
        refetch={handleRefetch}
        setShipping={handleSelectShippingAddress}
        setBilling={handleSelectBilling}
        shippingAddress={selectedShipping? selectedShipping : defaultShipping? defaultShipping : undefined}
      />
      <Stack>
        <StyledBox>
          <Box sx={{display: "flex"}}>
            <Title>
              {user?.isallowaddpatient? "Patient Address" : "Shipping Address"}
            </Title>
            <Box sx={{width: "100%", mt: "20px"}}>
              
              {selectedShipping?
                <Grid container spacing={2}>
                  <Grid xs={6} md={8}>
                    <Stack>
                      <StyledTypography>
                        {selectedShipping?.name}
                      </StyledTypography>
                      <StyledTypography>
                        {selectedShipping?.address1}
                      </StyledTypography>
                      <StyledTypography>
                        {selectedShipping?.address2}
                      </StyledTypography>
                      <StyledTypography>
                        {selectedShipping?.city + ", " + selectedShipping?.state + ", " + selectedShipping?.zip}
                      </StyledTypography>
                      <StyledTypography>
                        {selectedShipping?.shiptype}
                      </StyledTypography>
                    </Stack>
                  </Grid>
                  <Grid xs={6} md={4}>
                    <Typography sx={{
                        color: "#000",
                        fontSize: "12px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={()=>{
                        if(user?.isallowaddpatient)
                          handleOpenModal(2) 
                        else 
                          handleOpenModal(0)
                      }}
                    >
                      {user?.isallowaddpatient? "Change Patient Address" : "Change Shipping Address"}
                      </Typography>
                  </Grid>
                </Grid>
              :(defaultShipping)?
                <Grid container spacing={2}>
                  <Grid xs={6} md={8}>
                    <Stack>
                      <StyledTypography>
                        {defaultShipping?.name}
                      </StyledTypography>
                      <StyledTypography>
                        {defaultShipping?.address1}
                      </StyledTypography>
                      <StyledTypography>
                        {defaultShipping?.address2}
                      </StyledTypography>
                      <StyledTypography>
                        {defaultShipping?.city + ", " + defaultShipping?.state + ", " + defaultShipping?.zip}
                      </StyledTypography>
                      <StyledTypography>
                        {defaultShipping?.shiptype}
                      </StyledTypography>
                    </Stack>
                  </Grid>
                  <Grid xs={6} md={4}>
                    <Typography sx={{
                        color: "#000",
                        fontSize: "12px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={()=>{
                        if(user?.isallowaddpatient)
                          handleOpenModal(2) 
                        else 
                          handleOpenModal(0)
                      }}
                    >
                      {user?.isallowaddpatient? "Change Patient Address" : "Change Shipping Address"}
                      </Typography>
                  </Grid>
                </Grid>
              :
                  <Typography sx={{
                    color: "#000",
                    fontSize: "16px",
                    textDecoration: "underline",
                    mt:"-15px",
                    cursor: "pointer",
                  }}
                    onClick={()=>{
                      if(user?.isallowaddpatient)
                        handleOpenModal(2) 
                      else 
                        handleOpenModal(0)
                    }}
                  >
                    {user?.isallowaddpatient? "Select a Patient Address" : "Select a Shipping Address"}
                  </Typography>
              }
            </Box>
          </Box>
          <Box sx={{display: "flex", mt: "50px"}}>
            <Title>
              Billing Address
            </Title>
            <Box sx={{width: "100%", mt: "20px"}}>
              {selectedBilling?
                <Grid container spacing={2}>
                  <Grid xs={6} md={8}>
                    <Stack>
                      <StyledTypography>
                        {selectedBilling?.name}
                      </StyledTypography>
                      <StyledTypography>
                        {selectedBilling?.address1}
                      </StyledTypography>
                      <StyledTypography>
                        {selectedBilling?.address2}
                      </StyledTypography>
                      <StyledTypography>
                        {selectedBilling?.city + ", " + selectedBilling?.state + ", " + selectedBilling?.zip}
                      </StyledTypography>
                    </Stack>
                  </Grid>
                  <Grid xs={6} md={4}>
                    <Typography sx={{
                        color: "#000",
                        fontSize: "12px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={()=>{handleOpenModal(1)}}
                    >
                        Change Billing Address
                      </Typography>
                  </Grid>
                </Grid>
              :(defaultBilling)?
                <Grid container spacing={2}>
                  <Grid xs={6} md={8}>
                    <Stack>
                      <StyledTypography>
                        {defaultBilling?.name}
                      </StyledTypography>
                      <StyledTypography>
                        {defaultBilling?.address1}
                      </StyledTypography>
                      <StyledTypography>
                        {defaultBilling?.address2}
                      </StyledTypography>
                      <StyledTypography>
                        {defaultBilling?.city + ", " + defaultBilling?.state + ", " + defaultBilling?.zip}
                      </StyledTypography>
                    </Stack>
                  </Grid>
                  <Grid xs={6} md={4}>
                    <Typography sx={{
                        color: "#000",
                        fontSize: "12px",
                        textDecoration: "underline",
                        cursor: "pointer",
                        mt:"-15px"
                      }}
                      onClick={()=>{handleOpenModal(1)}}
                    >
                        Change Billing Address
                      </Typography>
                  </Grid>
                </Grid>
              :
                  <Typography 
                    sx={{
                      color: "#000",
                      fontSize: "16px",
                      textDecoration: "underline",
                      cursor: "pointer",
                      mt:"-15px",
                    }}
                    onClick={()=>{handleOpenModal(1)}}
                  >
                    Select a Billing Address
                  </Typography>
              }
            </Box>
          </Box>
        </StyledBox>
      </Stack>
    </StyledProductCart>
  );
};

const StyledProductCart = styled(Box)(
  () => ` 
  display: flex;
  flex-direction: column; 
`
);

const StyledInput = styled(TextField)(
  ({ error }) => `
 && { 
    .MuiInputBase-root  {
        border: 1px solid #ebebeb;
            border-radius: 0px; 

    } 

    border-radius: 4px; 
    width: 100%;
    clear: both;
    display: block; 
    input {  
        padding: 10px;

    }
 
    label {
            font-size: 15px;
            top: -3px;
    }
    .MuiInputLabel-shrink {
      top: -5px;
      left: -10px;
    }
    .MuiOutlinedInput-notchedOutline{
      border: 0;
    }
 }
`
);

const StyledBox = styled(Box)(
  () => `
    padding: 30px 150px 30px 150px;
`
);
const StyledTypography = styled(Typography)(
  () => `
    color: #000; 
    font-weight: 500;
    font-size: 16px !important;
    letter-spacing: 1.5px; 
    padding: 3px 30px 0px;
`
);

const Title = styled(Typography)(
  () => `
    color: #000; 
    font-weight: 700;
    font-size: 20px !important;
    letter-spacing: 1.5px; 
    width: 300px
`
);

export default PatientPage;
