import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import MedSolutionsLogo from "~/assets/images/medsolutionlogo.png";
import { AuthContext } from "~/contexts/authContext";
import React from "react";

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false }) {
  const logo = (
    <Box component="img" src={MedSolutionsLogo} sx={{ width: "250px" }} />
  );

  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleChangePage = () => {
    navigate("/product/1");
  };

  if (disabledLink) {
    return <>{logo}</>;
  }
  if (user) {
    return (
      <RouterLink to="/product/1" onClick={() => navigate("/product/1")}>
        {logo}
      </RouterLink>
    );
  } else
    return (
      <RouterLink to="/" onClick={handleChangePage}>
        {logo}
      </RouterLink>
    );
}
