/**
 * 
 * 
 * Displays list of all user orders
 * Invoice payment is also here
 * 
 * 
 */

// material
import { useState, useCallback, useEffect } from "react";

import { Container, Typography, Button, Modal } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import DataTable from "~/components/TableData/index";
import Page from "@components/Page";
import { If, Then, Else } from "react-if";
import SuspenseLoader from "@components/SuspenseLoader";
import { styled } from "@mui/material/styles";
import Toast from "~/components/Notification";
import InvoicePayment from "./InvoicePayment";
import ShippingDetails from "./Shipping";
// ----------------------------------------------------------------------

export default function OrderPage() {

  const [openModal, setOpenModal] = useState(false);
  const [openTracking, setOpenTracking] = useState(false);

  const [trackingID, setTrackingID] = useState<number>();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isError, setError] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);

  const location = useLocation();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenTracking = (id : number) => {
    setOpenTracking(true);
    setTrackingID(id)
  }
  const handleCloseTracking = () => setOpenTracking(false);

  const handleOpen = useCallback((msg : string, isError : boolean) => {
    setError(isError)
    setIsOpen(true)
    setMessage(msg)
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setMessage("")
    setError(false)
  },[isError]);

  return (
    <>
      <Page sx={{ zIndex: 2 }} title="Dashboard: Orders">
        <Toast
          open={isOpen}
          message={message}
          onClose={handleClose}
          severity={isError? "error" : "success"}
        />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <>
          <InvoicePayment
            isOpen={openModal}
            onClose={handleCloseModal}
            toast={handleOpen}
          />
        </>
      </Modal>
      <Modal
        open={openTracking}
        onClose={handleCloseTracking}
      >
        <ShippingDetails
          isOpen={openTracking}
          id={trackingID}
          onClose={handleCloseTracking}
        />
      </Modal>
      <If condition={false}>
        <Then>
          <SuspenseLoader />
        </Then>
        <Else>
          <Container sx={{ position: "relative" }} maxWidth="xl">
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
              <Title gutterBottom variant="h5">
                Orders
              </Title>
              <Box sx={{width: "200px"}}>
                <StyledButton onClick={()=>{handleOpenModal()}}>
                  Pay Invoices
                </StyledButton>
              </Box>
            </Box>
            <StyledContainer>
              <DataTable
                onOpenTracking={handleOpenTracking}
                openModal={openModal}
              />
            </StyledContainer>
          </Container>
        </Else>
      </If>
    </Page>
    </>
  );
}

const Title = styled(Typography)(
  () => ` 
    font-style: normal;
    font-weight: 600!important;
    font-size: 30px!important;
    line-height: 82px!important; 
    color: #44A2DF; 
`
);

const StyledContainer = styled("section")(
  () => `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

`);

const StyledButton = styled(Button)(
 () => `
background: #1CB7EC;
border-radius: 15px;
font-size: 18px !important;
font-weight: 400;
height: 44px;
line-height: 22px;
padding: 0;
text-transform: uppercase;
width: 100%;
margin-top: 35px;
display: flex;
align-items: center;
justify-content: center;
color: #FFF;
gap: 5px;

:hover {
  background: #5569ff;
  color: #FFF;
}

`
);