import React, {
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
// material
import {
  Button,
  Card,
  Typography,
  CardMedia,
  CardContent,
  CardActions,
  styled,
  Box,
  Autocomplete,
  TextField,
  Stack,
  Chip,
  Tooltip,
  IconButton,
  Grid
} from "@mui/material";
// components
import { useNavigate, useLocation } from "react-router";
import { UpdateCustomerCart, deleteCustomerCartProduct } from "~/repositories/customerOrder.service";
import { ShopContext } from "~/contexts/ShopContext";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Taost } from "@components/Toast";
import Modal from "@components/Modal/NotificationModal";

const ButtonAdd = styled(Button)(
  () => `
    background: #FFF;
    border: 1px solid #000;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding: 0;
    text-transform: uppercase;
    width: 120px;
    // flex: 1;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding-top: 3px; 
    padding-bottom: 3px; 
    :hover {
      background: #000;
      color: #FFF;
    }

`
);

const StyledSelect = styled(Autocomplete)(
  ({ theme }) => ` 
  input {
    height: 20px !important;
    font-size: 12px;
  }
  fieldset {
    border: 1px solid #000 !important;
    border-radius: 0px !important;
  }
  .MuiOutlinedInput-root {
    padding-right: 40px !important;
  }
`
);

const QuantityInput = styled(TextField)(
  ({ theme }) => ` 
  width: 80px;
  input {
    height: 4px !important;
  }
  fieldset {
    border: 1px solid #000 !important;
    borderRadius: 10px !important;
  }

`
);

const StyledCostumerPrice = styled(Box)(
  () => `
  font-family: proxima-nova,"Helvetica Neue",Helvetica,Helvetica,Arial,sans-serif;
  line-height: 40px;
  font-size: 20px;
  font-weight: 700;
  color: #1CB7EC; 
  margin: auto;
`
);

const ProductQuantity: FC<any> = ({ disabled, onChange, quantity }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "70px",
        columnGap: "10px",
        ml:"20px"
      }}
    >
        <QuantityInput
          fullWidth
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          onChange={(e) =>
            onChange(
              parseInt(e.target.value)
            )
          }
          value={quantity}
        />
      {/* {disabled || quantity === 0 ? null : (
        <>
          <IconButton
            sx={{
              // border: "1px solid #000",
              color: "#000",
              padding: "5px",
              borderRadius: 0,
            }}
            onClick={() => onChange(quantity - 1)}
            disabled={quantity === 1}
          >
            <RemoveIcon fontSize="small" />
          </IconButton>
          <Typography
            sx={{
              fontFamily: "proxima_nova_ltsemibold",
              fontSize: "16px",
            }}
          >
            {quantity}
          </Typography>
        </>
      )}
      <IconButton
        disabled={disabled}
        sx={{
          padding: "5px",
          // border: "1px solid #000",
          color: "#000",
          borderRadius: 0,
        }}
        onClick={() => onChange(quantity + 1)}
      >
        <AddIcon fontSize="small" />
      </IconButton> */}
    </Box>
  );
};

const ShopProductCard: FC<any> = ({ product, cart }: { product: any, cart: any }) => {
  const { mainname, productimgurl } = product;
  const navigate = useNavigate();
  const location = useLocation();
  const firstTime = useRef(false);
  const firstTimeFilter = useRef(false);
  const { products, onChangeData } = useContext(ShopContext);
  const [openToast, setOpentToast] = useState<boolean>(false);
  const [openBackOrder, setOpenBackOrder] = useState<boolean>(false);
  const [productType, setProductType] = React.useState<
    Record<string, any> | undefined
  >();
  const [inCart, setInCart] = useState<boolean>(false);
  const [inCartQty, setInCartQty] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(product?.quantity || 1);
  const [qtyErrorMsg, setQtyErrorMsg] = useState<any>(undefined);
  const handleSelect = useCallback((event: any | null) => {
    if (event) {
      setProductType(event);
    }
  }, []);

  const handleSelectOneItem = useCallback(
    (Item: any): void => {
      const currPath = location.pathname
      navigate(`${currPath.split("/")[0]}/${currPath.split("/")[1]}/${currPath.split("/")[2]}/${Item.productdetails[0].productid}`)
      navigate(`/product/product-details/${Item.sku}?skuId=${Item.skuid}`);
    },
    [navigate]
  );

  const handleRemove = useCallback(
    async () => {
      if(productType && inCart){
        const filtred = products?.filter(
          (element: any) => element.productsubid !== productType.productsubid
        );
        await deleteCustomerCartProduct({
          productid: productType?.productid,
          productsubid: productType?.productsubid,
          qty: 1,
        });
        onChangeData(filtred);
      }
    },
    [products,productType, inCart]
  );

  const checkStockQty = useCallback(()=>{
    let error = false
    if(productType && quantity) {
        if(productType.availableqty_type < quantity && !productType.isnoninventory){
          //Warning
          setQtyErrorMsg(
            <Typography>
              Available <b>{productType.producttypewithval}</b> is <b>{productType.availableqty_type}</b> and the requested for <b>{quantity}</b>. If you continue with this order then it will be processed as a backorder, or you can click cancel and change the quantity to be the same as available quantity to be processed as a normal order. Would you like to confirm?
            </Typography>
          )
          error = true
        }else if(cart && !productType.isnoninventory){
          const productList = cart.filter((item:any)=>{return (item.productid === productType.productid && item.productsubid !== productType.productsubid)})
          if(productList.length > 0){
            const availableqty = productType.availableqty;
            let requestedqty = (productType.formulaqty * quantity);
            productList.forEach((item : any)=> {requestedqty += (item.formulaqty * item.quantity)})
            if(requestedqty > availableqty){
              setQtyErrorMsg(
                <Typography>
                    Available <b>{productType.productname}</b> is <b>{availableqty}</b> and the requested amount, including items in your cart, is <b>{requestedqty}</b>. If you continue with this order then it will be processed as a backorder, or you can click cancel and change the quantity to be the same as available quantity to be processed as a normal order. Would you like to confirm?
                </Typography>
                )
              error = true
            }
          }
        }
        if(error){
          setOpenBackOrder(true)
        }else{
          handleAddToCart()
        }
      }
    },[cart,quantity,productType])

  const handleAddToCart = useCallback(async () => {
    setOpentToast(true);
    if (
      !products
        ?.map((item: any) => item.productsubid)
        ?.includes(productType?.productsubid)
    ) {
      await UpdateCustomerCart({
        productid: productType?.productid,
        productsubid: productType?.productsubid,
        qty: quantity,
      });
      setInCartQty(quantity)
      onChangeData((prevSelected: any) => [
        ...prevSelected,
        {
          ...productType,
          productimgurl: product.productimgurl,
          quantity: quantity,
          sku: product.productdetails[0].sku,
        },
      ]);
    } else if (
      !products
        ?.map((item: any) => item.producttype)
        ?.includes(productType?.producttype)
    ) {
      await UpdateCustomerCart({
        productid: productType?.productid,
        productsubid: productType?.productsubid,
        qty: quantity,
      });
      setInCartQty(quantity)
      onChangeData((prevSelected: any) => [
        ...prevSelected,
        {
          ...productType,
          productimgurl: product.productimgurl,
          quantity: quantity,
          sku: product.productdetails[0].sku,
        },
      ]);
    } else if (
      products
        ?.map((item: any) => item.productsubid)
        ?.includes(productType?.productsubid)
    ) {
      let cartQty = quantity;
      products.forEach((item) => {
        if (item.productsubid == productType?.productsubid) {
          //cartQty = cartQty + item.quantity;
          item.quantity = quantity;
        }
      });
      await UpdateCustomerCart({
        productid: productType?.productid,
        productsubid: productType?.productsubid,
        qty: cartQty,
      });
      setInCartQty(cartQty)
      onChangeData(products);
    }
  }, [product, quantity, products, onChangeData, productType]);

  const handleQuantity = useCallback((qty : number) => {
    setQuantity(qty);
  },[])

  const defaultProps = useMemo(() => {
    return {
      options: product?.productdetails,
      getOptionLabel: (option: any) => option.producttypewithval,
    };
  }, [product]);

  useEffect(() => {
    if (product && product?.productdetails?.length > 0 && !firstTime.current) {
      setProductType(product?.productdetails[0]);
      firstTime.current = true;
    }
  }, [product, quantity]);

  useEffect(() => {
    if (products && products?.length > 0 && !firstTimeFilter.current) {
      firstTimeFilter.current = true;
      const filtred = products?.filter(
        (item: any) => item.productsubid == product?.productdetails[0].productsubid
      );
      if (filtred.length > 0) {
        setQuantity(filtred[0]?.quantity);
        setInCartQty(filtred[0]?.quantity)
      }
    }
  }, [product, quantity, products, productType]);

  useEffect(()=>{
    if(productType){
      const cartItem = cart?.find((item:any)=> item.productsubid == productType.productsubid)
      if(cartItem){
        setInCart(true)
        setQuantity(cartItem.quantity)
        setInCartQty(cartItem.quantity)
      }else{
        setInCart(false)
        setQuantity(1)
        setInCartQty(1)
      }
    }
  },[productType,cart,product])
  
  

  return (
    <>
      <Modal
        open={openBackOrder}
        handleClose={() => {
          setOpenBackOrder(false);
          setQtyErrorMsg(undefined)
        }}
        handleAction={()=>{
          handleAddToCart()
          setOpenBackOrder(false);
        }}
        title={"Back Order Warning"}
        contentText={qtyErrorMsg? qtyErrorMsg : ""}
      />
      <Taost
        message={"Added to cart"}
        severity="success"
        open={openToast}
        autoHideDuration={2000}
        // open={true}
        handleCloseSnack={() => setOpentToast(false)}
      />
      <Card
        sx={{
          height: "100%",
          minHeight: "446px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "0",
          p: "20px",
          position: "relative",
        }}
      >
        {productType?.lastorderdateStr && (
          <Typography
            sx={{
              fontFamily: "proxima_nova_rgregular,sans-serif",
              fontWeight: "500",
              position: "absolute",
              fontStyle: "italic",
              fontSize: "12px",
              left: "20px",
              color: productType?.orderdatecolor,
            }
          }
          >
            {productType?.lastorderdateStr
              ? "Last Order Date: " + productType?.lastorderdateStr
              : ""}
          </Typography>
        )}
        {productimgurl ? (
          <Box sx={{ height: "150px",mt:"18px" }}>
            <CardMedia
              component="img"
              height="140"
              image={productimgurl || "/static/image/Pas-dimage-disponible.jpg"}
              alt="Product Image"
              sx={{ height: "100%" }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              color="#000"
              fontWeight="600"
              textAlign={"center"}
              onClick={() => handleSelectOneItem(product)}
              letterSpacing="1.5 !important"
              sx={{
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              {mainname}
            </Typography>
          </Box>
        )}
        <CardContent sx={{ textAlign: "center", padding: "0 !important" }}>
          {productimgurl && (
            <Typography
              color="#000"
              fontWeight="600"
              textAlign={"center"}
              onClick={() => handleSelectOneItem(product)}
              letterSpacing="1.5 !important"
              sx={{
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              {mainname}
            </Typography>
          )}
          <Typography fontSize="14px" fontWeight="400" color="gray">
            {productType?.category /* + " " + productType?.manufacturer */}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pr: "10px",
            width: "100%",
            padding: 0,
          }}
        >
          {/* <Box display="flex" flexDirection="row" justifyContent="center">
          <Typography sx={{ color: "#000" }}>
            Price: $
            {product?.productdetails?.length > 0 &&
              product?.productdetails[0].customerprice}{" "}
            &nbsp;
          </Typography>
          <Typography sx={{color: "#000"}}>
          MSRP: $
          {product?.productdetails?.length > 0 &&
            product?.productdetails[0].msrp}
          </Typography>
        </Box> */}
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
          width={"100%"}
          ml={1}
        >
          {productType?.inventorystatuslbldetails && (
            <Typography
            sx={{
              fontSize: "13px",
              marginBottom: "5px",
              fontFamily: "proxima_nova_ltsemibold",
              color: productType?.inventorystatuslblcolor,
            }}
            >
              {productType?.inventorystatuslbldetails}
            </Typography>
          )}
          </Box>
          <Stack width={"100%"}>
            {/* <Box
              display="flex"
              flexDirection={"column"}
              gap="5px"
              marginBottom="10px"
              marginTop="10px"
              width="100%"
            >
              <StyledQuantity>Type:</StyledQuantity>
              <Stack direction="row" spacing={1}>
           
              </Stack>
              <StyledSelect
                {...defaultProps}
                value={productType}
                onChange={(event: any, newValue: any) => {
                  handleSelect(newValue);
                }}
                id="controllable-states-demo"
                sx={{ borderColor: "#000" }}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={"Type"}
                    sx={{ left: "30.5px", width: "150px" }}
                  />
                )}
              />
            </Box> */}
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              width={"100%"}
              columnGap={"10px"}
            >
              <Box width="60%">
                <Typography
                  sx={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    fontFamily: "proxima_nova_ltsemibold",
                  }}
                >
                  Type
                </Typography>
                {product?.productdetails.length > 0 ? (
                  <StyledSelect
                    {...defaultProps}
                    sx={{
                      flex: 1,
                      borderColor: "#000",
                    }}
                    value={productType || null}
                    onChange={(event: any, newValue: any) => {
                      handleSelect(newValue);
                    }}
                    id="controllable-states-demo"
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    disabled={Boolean(product?.productdetails.length === 1)}
                    freeSolo={Boolean(product?.productdetails.length === 1)}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: "14px !important" },
                        }}
                        // inputProps={{
                        //   input: {
                        //     fontSize: "14px !important",
                        //   },
                        // }}
                        size="small"
                      />
                    )}
                  />
                ) : (
                  product?.productdetails.map((product: any, index: number) => {
                    return (
                      <Chip
                        sx={{
                          backgroundColor: "#35b7ec",
                          color: "#fff",
                          ":hover": {
                            backgroundColor: "#35b7ec !important",
                            color: "#fff",
                          },
                        }}
                        label={product.producttypewithval}
                        variant="outlined"
                      />
                    );
                  })
                )}
              </Box>
              <Box flex={1}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    fontFamily: "proxima_nova_ltsemibold",
                    textAlign: "center",
                  }}
                >
                  Qty
                </Typography>
                <ProductQuantity
                  quantity={quantity}
                  onChange={handleQuantity}
                  disabled={Boolean(productType === undefined)}
                />
              </Box>
            </Box>
            {/* <Box
              marginTop="10px"
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              width={"100%"}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "proxima_nova_rgbold",
                  fontSize: "16px",
                }}
              >
                $
                {quantity > 0
                  ? (productType?.customerprice * quantity).toFixed(2)
                  : productType?.customerprice}{" "}
                &nbsp;
              </Typography>
              <ProductQuantity
                quantity={quantity}
                onChange={handleQuantity}
                disabled={Boolean(productType === undefined)}
              />
            </Box> */}
            {/* <StyledQuantity>
            <StyledCostumerPrice>
              {customerPrice > 0 ? "$" + customerPrice : ""}
            </StyledCostumerPrice>
          </StyledQuantity> */}
            <Box
              display={"flex"}
              alignContent="center"
              justifyContent={"center"}
              marginTop="10px"
              width={"100%"}
              gap={"5px"}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  alignSelf: "center",
                  color: "#35b7ec",
                  width: "50%",
                  fontFamily: "proxima_nova_rgbold",
                  flex: 1,
                }}
              >
                $
                {quantity > 0
                  ? (productType?.customerprice * quantity).toFixed(2)
                  : productType?.customerprice}
              </Typography>
              {inCart && (
                <Tooltip title={"Remove from cart"}>
                  <DeleteForeverIcon
                  onClick={() => handleRemove()}
                  sx={{ cursor: "pointer" }}
                  />
                </Tooltip>
              )}
              <ButtonAdd
                autoFocus
                sx={
                {
                  border: "unset",
                  color: "#fff",
                  background: "#35b7ec",
                  ":hover": {
                    background: "#000",
                    color: "#FFF",
                  },
                }
                }
                disabled={
                  Boolean(productType === undefined) || Boolean(quantity <= 0)
                }
                // startIcon={<AddIcon />}
                onClick={() => checkStockQty()}
              >
                {inCart? inCartQty == quantity? "IN CART" : "EDIT CART" : "ADD TO CART"}
              </ButtonAdd>

              {/* <Tooltip title="View Details">
                <IconButton
                  sx={{
                    color: "#000",
                    borderRadius: 0,
                    border: "1px solid #000",
                  }}
                  onClick={() => handleSelectOneItem(product)}
                >
                  <RemoveRedEyeIcon fontSize="small" />
                </IconButton>
              </Tooltip> */}
            </Box>
            {/* <Box
              display={"flex"}
              alignContent="center"
              marginTop="10px"
              width={"100%"}
              gap={"5px"}
            >
              <ButtonAdd autoFocus onClick={() => handleSelectOneItem(product)}>
                View Details
              </ButtonAdd>
            </Box> */}
          </Stack>
        </CardActions>
      </Card>
    </>
  );
};

export default ShopProductCard;
