import { useState, useCallback, useEffect, ChangeEvent, FC, useMemo } from "react";
import { 
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Grid,
    Typography,
    IconButton,
    Tooltip,
    styled,
    Dialog,
    DialogTitle,
    DialogContent,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { If, Then, Else } from "react-if";
import { Backdrop, CircularProgress } from "@mui/material";
import InvoiceTable from "~/components/Table/InvoiceTable";
import { handleFieldsStructure, tableDatastructure } from "~/helpers/tableStructure";
import { getAllUnPaidInvoices, GetInvoicePaymentTableStructure, MakeOrderPaymentInvoice } from "~/repositories/invoice.service";
import CartPaymentForm from "../CheckoutPage/Card/PayementPage/PayementForm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toast from "~/components/Notification";

interface IPagination {
    page: number;
    limit: number;
}

interface InvoicePayment {
    isOpen: boolean;
    onClose: ()=>void;
    toast: (msg : string, isError : boolean) => void;
}

const ITEMLIST_SHARED_DATA: Record<string, any> = {
    title: "Select Invoices for Payment"
};

const InvoicePayment: FC<InvoicePayment> = ({
        isOpen,
        onClose,
        toast
    })=> {

    const [pagination, setPagination] = useState<IPagination>({
        page: 0,
        limit: 100000,
    });

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [itemList, setItemList] = useState<any[]>([]);
    const [invoiceList, setInvoiceList] = useState<any[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [totalRowsStructure, setTotalRowsStructure] = useState([]);
    const [tableStructure, setTableStructure] = useState<Array<string>>([]);
    const [selectedInvoicesIDs, setSelectedInvoicesIDs] = useState<any[]>([]);
    const [selectedInvoices, setSelectedInvoices] = useState<any>([]);
    const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isError, setError] = useState<boolean>(false);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isPaying, setPaying] = useState<boolean>(false);
    const [statusCode, setStatusCode] = useState<number>(0);

    const totalBalance = useMemo(()=>{
        let total = 0;
        if(selectedInvoices){
            selectedInvoices.forEach((item : any)=>{
                total += parseFloat(item.balance)
            })
            return total.toFixed(2);
        }else{
            return 0
        }
    },[selectedInvoices])

    const tableRowsFieldsData = useCallback((fields: any, data: any) => {
        return tableDatastructure(fields, data);
    }, []);

    const getInvoices = useCallback(async()=>{
        await getAllUnPaidInvoices(pagination).then(
            (response)=>{
                const data = tableRowsFieldsData(
                    totalRowsStructure,
                    response.data.data
                );
                setItemList(response.data.data)
                setInvoiceList(data);
                setTotalRows(response.data.total);
                setLoading(false)
            },
            (error)=>{
                console.log(error)
            }
        )
    },[totalRowsStructure,pagination,tableRowsFieldsData])

    const getTableStructure = useCallback(async ()=>{
        await GetInvoicePaymentTableStructure().then(
            (response)=>{
                const structure = handleFieldsStructure(response);
                setTableStructure(Object.keys(structure).map(key => structure[key]));
                setTotalRowsStructure(response);
            },
            (error)=>{
                console.log(error)
            }
        )
    },[])

    const submitPayment = useCallback(async (data : any) =>{
        
        let orderinvoicespaymentsub : any[] = []
        selectedInvoices.forEach((item : any) => {
            orderinvoicespaymentsub.push({orderid: item.id, pendingamount: item.balance})
        });
        const date = data.expdate.split('/');
        const paymentdata = {
            amount: totalBalance,
            name: data.name,
            cc: data.cc,
            cvv: data.cvv.toString(),
            expirymonth: date[0],
            expiryyear: date[1],
            orderinvoicespaymentsub,
            ccaddress: data.ccaddress,
            ccstate: data.ccstate,
            cccity: data.cccity,
            cczipcode: data.cczipcode,
            cccountry: "US",
        }
        setSubmitting(true)
        await MakeOrderPaymentInvoice(paymentdata).then(
            (response)=>{
                if(response.statusCode != 200){
                    setSubmitting(false)
                    handleOpen(response.message)
                    setError(true)
                }else{
                    setSubmitting(false)
                    toast(response.message, false)
                    onClose()
                }
            },
            (error)=>{
                console.log(error)
                setSubmitting(false)
            }
        )
    },[selectedInvoices, totalBalance])

    const handlePageChange = useCallback((event: any, newPage: number): void => {
        setPagination(state => ({ ...state, page: newPage }));
        getInvoices()
      }, []);
    
    const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
        setPagination(state => ({
        ...state,
        limit: parseInt(event.target.value),
        }));
        getInvoices()
    },[]);

    const handleSelectedItems = useCallback((items: any[]) => {
        setSelectedInvoicesIDs(items)
    },[])

    const handleToPayment = useCallback(()=>{
        const invoices = itemList.filter((item) => selectedInvoicesIDs.includes(item.id))
        setSelectedInvoices(invoices)
        setPaying(true)
    },[selectedInvoicesIDs, itemList])

    const handleOpen = useCallback((msg : string) => {
        setIsToastOpen(true)
        setMessage(msg)
      }, []);
    
      const handleClose = useCallback(() => {
        if(statusCode == 200){
            onClose()
            setIsToastOpen(false)
            setMessage("")
            setError(false)
        }else{
            setIsToastOpen(false)
            setMessage("")
            setError(false)
        }
      },[isError, statusCode]);

    useEffect(() => {
        getTableStructure();
    }, [isOpen]);

    useEffect(() => {
        if (totalRowsStructure) getInvoices();
    }, [pagination, getInvoices, totalRowsStructure]);

    return(
        <>
         <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        maxWidth="lg"
        fullWidth={true}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        sx={{[theme.breakpoints.down('md')]: {mt:"0px", height:"100%"}, [theme.breakpoints.up('md')]: {mt:"0px", height:"800px"} }}
        >
        <Toast
            open={isToastOpen}
            message={message}
            onClose={handleClose}
            severity={isError? "error" : "success"}
        />
        <DialogContent sx={{py:5, height: "700px",[theme.breakpoints.down('md')]:{px: 2},[theme.breakpoints.up('md')]:{px: 10} }}>
            <Box sx={{position: "absolute", top: 0, right: 0, p: 2}}>
                <CloseButton onClick={onClose}>x</CloseButton>
            </Box>
            <If condition={Boolean(invoiceList.length === 0 || isLoading)}>
            <Then>
                {isLoading?
                <Backdrop
                sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
                open={Boolean(isLoading)}
                >
                <CircularProgress color="inherit" />
                </Backdrop>
                :
                <Box sx={{m: "auto", width:"50%", ml:"40%", mt:"10%"}}>
                    <Typography sx={{fontWeight:"700", fontSize: "20px"}}>
                        No invoices to be paid
                    </Typography>
                </Box>
                }
            </Then>
            <Else>
                <> 
                {isPaying?
                    <Box sx={{[theme.breakpoints.down('md')]:{px: 3},[theme.breakpoints.up('md')]:{px: "150px"}}}>
                        <Tooltip title="Back to Invoice Selection">
                        <IconButton 
                            sx={{
                                position:"absolute",
                                top:0,
                                left: 0,
                                m: 2,
                            }}
                            onClick={()=>{setPaying(false)}}
                        >
                            <ArrowBackIcon fontSize="large"/>
                        </IconButton>
                        </Tooltip>
                        <TableContainer sx={{[theme.breakpoints.down('md')]:{mt: "30px"},[theme.breakpoints.up('md')]:{mt: "20px", maxHeight: "350px"}}}>
                            <Table>
                                <TableHead>
                                    <TableCell>Order Number</TableCell>
                                    <TableCell>Order Status</TableCell>
                                    <TableCell>Shipping</TableCell>
                                    <TableCell>Tax</TableCell>
                                    <TableCell>Total Amount</TableCell>
                                    <TableCell>Balance</TableCell>
                                </TableHead>
                                <TableBody>
                                    {selectedInvoices.map((item : any) =>{
                                        return(
                                            <TableRow key={item.id}>
                                                <TableCell>{item.ordernumber}</TableCell>
                                                <TableCell>{item.orderstatus}</TableCell>
                                                {/* <TableCell>{"$"+item.shippingamount}</TableCell> */}
                                                <TableCell>{"$"+item.taxamount}</TableCell>
                                                <TableCell>{"$"+item.totalamount}</TableCell>
                                                <TableCell>{"$"+item.balance}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer >
                        <Grid container spacing={2} sx={{mt:"10px", ml:"10px" }}>
                            <Typography sx={{fontWeight: "700", fontSize: "18px"}}>Total Balance: {"$"+totalBalance}</Typography>
                        </Grid>
                        <Box sx={{mt: "50px"}}>
                            <CartPaymentForm onPayment={submitPayment} isSubmitting={isSubmitting} invoicePayment={true} paymentTxt="Make Payment"/>
                        </Box>
                    </Box>
            
                :
                    <>
                    <InvoiceTable
                        itemlist={invoiceList}
                        pagination={pagination}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        tableRowColumns={tableStructure}
                        totalRows={totalRows}
                        sharedData={ITEMLIST_SHARED_DATA}
                        handleSelectedItems={handleSelectedItems}
                        onPayment={handleToPayment}
                    />
                    </>
                }
                </>
            </Else>
            </If>
            </DialogContent>
        </Dialog>
        </>
    )
}

const CloseButton = styled("div")(
    () => `
      && {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #000;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 25px;
      }
  `
  );

export default InvoicePayment