/**
 * 
 * 
 * UPS Tracking information
 * 
 * 
 */
import {FC, useCallback, useEffect, useState} from "react";
import { 
    Box,
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme,
    styled,
    Divider,
    TextField,
    InputLabel,
    NativeSelect,
    FormControl 
 } from "@mui/material";
import { TrackShippment, getTrackingDetails } from "~/repositories/customerOrder.service";
import { Backdrop, CircularProgress } from "@mui/material";

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface Shipping {
    isOpen: boolean;
    id: any;
    onClose: ()=>void;
}

const ShippingDetails: FC<Shipping> = ({
    isOpen,
    id,
    onClose,
})=>{

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [shippments, setShippments] = useState<any>([]);
    const [tracking, setTracking] = useState<any>()
    const [shippingProgress, setShippingProgress] = useState<any>();
    const [isError, setIsError] = useState<boolean>(false);
    const [value, setValue] = useState(0);
    const [selectedOrder,setSelectedorder] = useState<any>();

    const [inputValue, setInputValue] = useState('');

    const GetShipping = useCallback(async()=>{
        await getTrackingDetails(id).then(
            (response)=>{
                getTracking(response.data[0])
                setShippments(response.data)
            },
            (error)=>{
                console.log(error)
            }
        )
    },[id])

    const getTracking = useCallback(async(data:any)=>{
        data = {
            orderid: data.orderid,
            ordershippingid: data.ordershippingid,
            trackingno: data.trackingnumber
        }
        await TrackShippment(data).then(
            (response)=>{
                setTracking(response.data.trackresponseRtn)
                setIsError(response.data.iserror)
                setShippingProgress(response.data.trackresponseRtn.activitydetail.reverse())
            },
            (error)=>{
                console.log(error)
            }
        )
    },[])

    const handleSelectOrder = useCallback((event : any)=>{
        const selectedOrder = shippments[event.target.value];
        getTracking(selectedOrder)
    },[selectedOrder, shippments])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
      };

    useEffect(()=>{
        GetShipping()
    },[isOpen,id])

    return(
        <>
        <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        maxWidth="md"
        fullWidth={true}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ maxHeight: "800px",minHeight:"500px", mt:"40px" }}
        >
            <Box sx={{position: "absolute",top: 0, right: 0, p: 2}}>
                <CloseButton onClick={onClose}>x</CloseButton>
            </Box>
            <DialogTitle sx={{mt:"30px"}}>
                {!isError && (
                <Box sx={{position: "absolute",top: 0, left: 0, p: 2}}>
                    <Typography>
                       <b>Last Updated:</b> {tracking?.activitydetail[0]?.datetime}
                    </Typography>
                </Box>
                )}
                <Box display="flex" justifyContent="space-between" sx={{mt:"15px"}}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab sx={{fontSize:"16px"}} label="Shipment Details" {...a11yProps(0)} />
                        <Tab sx={{fontSize:"16px"}} label="Shipping Progress" {...a11yProps(1)} />
                    </Tabs>
                    <Box sx={{mr: "50px"}}>
                        <FormControl>
                            <InputLabel variant="standard" shrink={true}>
                                Tracking Numbers
                            </InputLabel>
                            <NativeSelect
                                defaultValue={0}
                                inputProps={{
                                    name: 'tracking number',
                                    id: 'uncontrolled-native',
                                }}
                                onChange={handleSelectOrder}
                                >
                                {shippments?.map((item : any, index :number)=>{
                                    return(
                                        <option value={index}>{item.trackingnumber}</option>
                                    );
                                })}
                            </NativeSelect>
                        </FormControl>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ height: "700px",px: 10, py:5, ml:13 }}>
            {!isError?
            <>
            <TabPanel value={value} index={0}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <ShipmentLabel>
                            Tracking Number
                        </ShipmentLabel>
                        <ShipmentDetails>
                            {tracking?.trackingno}
                        </ShipmentDetails>
                    </Grid>
                    <Grid item xs={6}>
                        <ShipmentLabel>
                            Shipped On
                        </ShipmentLabel>
                        <ShipmentDetails>
                            {tracking?.shippmentdate}
                        </ShipmentDetails>
                    </Grid>
                    <Grid item xs={6} sx={{mt:"10px"}}>
                        <ShipmentLabel>
                            Service
                        </ShipmentLabel>
                        <ShipmentDetails>
                            {tracking?.category}
                        </ShipmentDetails>
                    </Grid>
                    <Grid item xs={6} sx={{mt:"10px"}}>
                        <ShipmentLabel>
                            Weight
                        </ShipmentLabel>
                        <ShipmentDetails>
                            {tracking?.weight}
                        </ShipmentDetails>
                    </Grid>
                    <Grid item xs={6} sx={{mt:"10px"}}>
                        <ShipmentLabel>
                            Status
                        </ShipmentLabel>
                        <ShipmentDetails>
                            {tracking?.activitydetail[tracking?.activitydetail.length -1]?.statusdescription}
                        </ShipmentDetails>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {shippingProgress?.map((item : any)=>{
                        return(
                            <>
                             <Grid item xs={6} sx={{mb: 1}}>
                               <ShipmentDetails>
                                    {item.datetime.split(" ")[0]}
                                </ShipmentDetails>
                                <ShipmentDetails>
                                    {item.datetime.split(" ")[1]}
                                </ShipmentDetails>
                            </Grid>
                            <Grid item xs={6}>
                                <ShipmentLabel>
                                    {item.statusdescription}
                                </ShipmentLabel>
                                <ShipmentDetails>
                                    {item.city && (item.city + ", " + item.stateprovincecode)}
                                </ShipmentDetails>
                            </Grid>
                            </>
                        );
                    }).reverse()}
                </Grid>
            </TabPanel>
            </>
            :
            <>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={!tracking}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {(tracking && isError) && (
                    <Box sx={{display:"flex",justifyContent:"center", ml:"-150px", py: "50px"}}>
                        <Typography sx={{fontWeight: "700"}}>
                            Tracking details could not be found for this tracking number
                        </Typography>
                    </Box>
                )}
            </>
            }
            </DialogContent>


        </Dialog>
        </>
    );
}

const CloseButton = styled("div")(
    () => `
      && {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #000;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 25px;
      }
  `
  );

  const ShipmentLabel = styled(Typography)(
    () => `
      && {
        font-weight: 700;
        font-size: 18px;
      }
  `
  );

  const ShipmentDetails = styled(Typography)(
    () => `
      && {
        font-size: 18px;
      }
  `
  );

export default ShippingDetails