/*

Checkout Proccess

This page handles the main layout of components

RHFStepper handles datachanges along with Shop Context,

Shop Context is a parent component of the project so that it can be called throughout the project and saves order data

Note* Patient, Billing folders are no longer in use

Shipping Folder contains all Shipping/Patient and Billing address handeling



*/

import { FC, useContext, useCallback, useRef, useState, useEffect } from "react";
import { Divider } from "@mui/material";
import React, { useMemo } from "react";
import { styled, Grid, Typography, Box } from "@mui/material";
import { ReactNode } from "react";
import { GetPatient as Get, GetShipping } from "~/repositories/customerContact.service";
// import { GetPatient as Get } from "~/repositories/customerContact.service";
import { ShopContext } from "~/contexts/ShopContext";
import { useBillingByIDQuery } from "~/reactQueryHooks/useQueryBilling";
import { AuthContext } from "~/contexts/authContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
interface cartprops {
  title: string;
  children: ReactNode;
  buttons: ReactNode;
  activeStep: number;
  handleBack: (step : number)=>void;
}

const StyledTitle = styled(Typography)(
  () => `  
  font-weight: 400!important;
  font-size: 14px!important;
  line-height: 20px!important;
  color: #000;
  clear: both;
  display: block;
  display: flex;
  align-items: center;
  gap: 10px;
`
);

const OrderSummary = ({ title }: { title: any }) => {
  const { products, order, onChangeOrder } = useContext(ShopContext);
  const [shipping, setShipping] = useState<any>();
  const { user } = useContext(AuthContext);
  const GetPatientInfo = useRef(Get);
  const GetShippingInfo = useRef(GetShipping)
  
  const SearchPatients = useCallback(async () => {
    if (order?.shipto_id != 0) {
      if(user.isallowaddpatient){
      await GetPatientInfo.current(order.shipto_id.toString()).then(
        (response: any) => {
          setShipping(response.data[0]);
        }
      );
      }else{
        await GetShippingInfo.current(order.shipto_id.toString()).then(
          (response: any) => {
            setShipping(response.data[0]);
          }
        );
      }
    }
  }, [order, user]);

  const { isLoading, data: billing } = useBillingByIDQuery(order?.billto_id);

  const shippingFee = useMemo(() => {
    let shippingTotal = 0
    if(shipping && shipping.freeshippingoverorderamt>0 && !(shipping.freeshippingoverorderamt<=order.netamount)){
      shippingTotal = shipping.flateshippingrate
    }else{
      shippingTotal = 0;
    }
    return shippingTotal;
  }, [order,products,shipping]);  

  const subTotal = React.useMemo(() => {
    if(products){
      return products
        ? products.reduce((prev, curr) => {
            const price = curr.customerprice;
            return prev + price * curr.quantity;
          }, 0) + shippingFee
        : 0;
    }else{
      return 0
    }
  }, [products,shippingFee]);
  

  const taxPayed = useMemo(() => {
    let taxableTotal = 0;
    products?.forEach(function (product){
      if(product.istaxable){
        taxableTotal += (product.customerprice * product.quantity);
      }
    })
    const amount = order.taxpercent * taxableTotal
    return amount
  }, [order,products]);  
  
  React.useEffect(() => {
    onChangeOrder({
      ...order,
      totalamount: parseFloat(subTotal + taxPayed).toFixed(2),
      taxamount: parseFloat(taxPayed.toFixed(2)),
      netamount: (subTotal - shippingFee).toFixed(2),
      shippingamount: shippingFee? shippingFee : 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangeOrder, subTotal, taxPayed, shippingFee]);

  React.useEffect(() => {
     SearchPatients();
  }, [SearchPatients]);

  return (
    <>
      <StyledOderSammury>
        <StyledOder>
          

          {title != "Shopping Cart" && title != "Shipping Details" && (
            <>
            {user.isallowaddpatient?
              <Box
                sx={{
                  marginLeft: "5px",
                  paddingTop: "5px",
                  marginRight: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "proxima_nova_ltsemibold",
                    fontSize: "16px",
                  }}
                >
                  Patient Details
                </Typography>
                <Divider
                  sx={{
                    height: "2px",
                    background: "#000",
                  }}
                />
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>First Name:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {shipping?.patientfirstname}
                  </span>
                </StyledTitle>
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>Last Name:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {shipping?.patientlastname}
                  </span>
                </StyledTitle>
                {shipping?.patientid && (
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>Patient ID:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {shipping?.patientid}
                  </span>
                </StyledTitle>
                )}
                {shipping?.birthdate && (
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>DOB:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {shipping?.birthdate.slice(0, 10)}
                  </span>
                </StyledTitle>
                )}
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>Address 1:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {shipping?.address1}
                  </span>
                </StyledTitle>
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>Address 2:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {shipping?.address2}
                  </span>
                </StyledTitle>
                <Box
                  sx={{ marginBottom: "5px", columnGap: "10px" }}
                  display={"flex"}
                >
                  <StyledTitle>
                    <strong>State:</strong>{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      {shipping?.state}
                    </span>
                  </StyledTitle>
                  <StyledTitle>
                    <strong>City:</strong>{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      {shipping?.city}
                    </span>
                  </StyledTitle>
                  <StyledTitle>
                    <strong>Zip Code:</strong>{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      {shipping?.zip}
                    </span>
                  </StyledTitle>
                </Box>

                <Divider
                  sx={{
                    width: "100%",
                    borderColor: "#ddd",
                    background: "#ddd",
                  }}
                />
              </Box>
              :
              <Box
                sx={{
                  marginLeft: "5px",
                  paddingTop: "5px",
                  marginRight: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "proxima_nova_ltsemibold",
                    fontSize: "16px",
                  }}
                >
                  Shipping Details
                </Typography>
                <Divider
                  sx={{
                    height: "2px",
                    background: "#000",
                  }}
                />
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>Name:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {shipping?.name}
                  </span>
                </StyledTitle>
                
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>Address 1:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {shipping?.address1}
                  </span>
                </StyledTitle>
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>Address 2:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {shipping?.address2}
                  </span>
                </StyledTitle>
                <Box
                  sx={{ marginBottom: "5px", columnGap: "10px" }}
                  display={"flex"}
                >
                  <StyledTitle>
                    <strong>State:</strong>{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      {shipping?.state}
                    </span>
                  </StyledTitle>
                  <StyledTitle>
                    <strong>City:</strong>{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      {shipping?.city}
                    </span>
                  </StyledTitle>
                  <StyledTitle>
                    <strong>Zip Code:</strong>{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      {shipping?.zip}
                    </span>
                  </StyledTitle>
                </Box>
                <Divider
                  sx={{
                    width: "100%",
                    borderColor: "#ddd",
                    background: "#ddd",
                  }}
                />
              </Box>
              }
              <Box
                sx={{
                  marginLeft: "5px",
                  paddingTop: "5px",
                  marginRight: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "proxima_nova_ltsemibold",
                    fontSize: "16px",
                  }}
                >
                  Billing Details
                </Typography>
                <Divider
                  sx={{
                    height: "2px",
                    background: "#000",
                  }}
                />
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>Name:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {billing?.name}
                  </span>
                </StyledTitle>
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>Address 1:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {billing?.address1}
                  </span>
                </StyledTitle>
                <StyledTitle sx={{ marginBottom: "5px" }}>
                  <strong>Address 2:</strong>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {billing?.address2}
                  </span>
                </StyledTitle>
                <Box
                  sx={{ marginBottom: "5px", columnGap: "10px" }}
                  display={"flex"}
                >
                  <StyledTitle>
                    <strong>State:</strong>{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      {billing?.state}
                    </span>
                  </StyledTitle>
                  <StyledTitle>
                    <strong>City:</strong>{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      {billing?.city}
                    </span>
                  </StyledTitle>
                  <StyledTitle>
                    <strong>Zip Code:</strong>{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      {billing?.zip}
                    </span>
                  </StyledTitle>
                </Box>
                <Divider
                  sx={{
                    width: "100%",
                    borderColor: "#ddd",
                    background: "#ddd",
                  }}
                />
              </Box>
            </>
          )}
            <>
              <StyledOrderTitle
                sx={{
                  textAlign: "start",
                  fontSize: "19px",
                }}
              >
                SubTotal
                <PriceTotal> {"$ " + (subTotal - shippingFee).toFixed(2)}</PriceTotal>
              </StyledOrderTitle>
              <Divider
                sx={{
                  width: "100%",
                  borderColor: "#ddd",
                  background: "#ddd",
                }}
              />
            </>
          <StyledContent>
            {/* <StyledPrice>
              Sub Total <Price> {"$ " + subTotal.toFixed(2)}</Price>
            </StyledPrice> */}
            {order?.taxpercent != 0 && (
              <StyledPrice>
                Tax <Price> {"$ " + taxPayed.toFixed(2)}</Price>
              </StyledPrice>
            )}
           {shippingFee > 0 && (
           <StyledPrice>
              Shipping Fee 
                <Price> {"$ " + shippingFee.toFixed(2)}</Price>
            </StyledPrice> 
          )}
          </StyledContent>
          {title !== "Shopping Cart" && (
            <Divider
              sx={{
                width: "100%",
                background: "#ddd",
              }}

            />
          )}
          <StyledOrderFooterTitle
            sx={{
              textAlign: "start",
              fontSize: "19px",
            }}
          >
            Total
            <PricefooterTotal>
              {" "}

                {"$ " + (subTotal + taxPayed  ).toFixed(2)}
              
            </PricefooterTotal>
          </StyledOrderFooterTitle>
        </StyledOder>
      </StyledOderSammury>
    </>
  );
};

export const CartLayout: FC<cartprops> = ({ handleBack, activeStep, title, children, buttons }) => {
  return (
    <StyledCartLayouat>
      <StyledLeft>
        <Box sx={{display:"flex"}}>
          <Typography
            sx={{
              lineHeight: "1.5",
              fontFamily: "Public Sans,sans-serif",
              fontWeight: activeStep == 0? "700" : "300",
              display: "block",
              fontSize: "20px",
              cursor: activeStep != 0? "pointer" : "",
              "&:hover": {
              },
            }}
            color="#000"
            onClick={()=>{if(activeStep != 0) handleBack(0)}}
          >
            Shopping Cart &nbsp;
          </Typography>
          {activeStep >= 1 && (
          <>
          
          <Typography
            sx={{
              lineHeight: "1.5",
              fontFamily: "Public Sans,sans-serif",
              fontWeight: activeStep == 1? "700" : "300",
              display: "block",
              fontSize: "20px",
              cursor: activeStep != 1? "pointer" : "",
            }}
            color="#000"
            onClick={()=>{if(activeStep != 1) handleBack(1)}}
          >
            /&nbsp;Shipping&nbsp;
          </Typography>
          </>
          )}
          {activeStep >= 2 && (
          <>
          
          <Typography
            sx={{
              lineHeight: "1.5",
              fontFamily: "Public Sans,sans-serif",
              fontWeight: activeStep == 2? "700" : "300",
              display: "block",
              fontSize: "20px",
              cursor: activeStep != 2? "pointer" : "",
            }}
            onClick={()=>{if(activeStep != 2) handleBack(2)}}
            color="#000"
          >
            /&nbsp;Order Review&nbsp;
          </Typography>
          </>
          )}
          {activeStep >= 3 && (
          <>
          <Typography
            sx={{
              lineHeight: "1.5",
              fontFamily: "Public Sans,sans-serif",
              fontWeight: activeStep == 3? "700" : "300",
              display: "block",
              fontSize: "20px",
              cursor: activeStep != 3? "pointer" : "",
            }}
            onClick={()=>{if(activeStep != 3) handleBack(3)}}
            color="#000"
          >
            /&nbsp;Payment&nbsp;
          </Typography>
          </>
          )}
        </Box>
        <StyledProdact>{children}</StyledProdact>
      </StyledLeft>
      <Box display="flex" flexDirection="column">
        <Typography
          sx={{
            lineHeight: "1.5",
            fontFamily: "Public Sans,sans-serif",
            fontWeight: "700",
            display: "block",
            fontSize: "20px!important",
            mb: "20px",
          }}
          color="#000"
        >
          {title === "Shopping Cart" ? "Cart Totals" : "Order Summary"}{" "}
        </Typography>
        <OrderSummary title={title} />
        {buttons}
      </Box>
    </StyledCartLayouat>
  );
};

const StyledCartLayouat = styled(Box)(
  () => ` 
      gap: 40px;
      display: flex;
      justify-content: center;
      min-height: 380px;
      padding: 24px;
      width: 100%;

      @media (max-width:960px) {
        flex-direction: column;
      }
`
);
const StyledLeft = styled(Box)(
  () => `
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
`
);
const StyledProdact = styled(Box)(
  () => `
    background: #FFF;
    gap: 44px;
    display: grid;
    max-width: 1000px;
`
);

const Price = styled(Typography)(
  () => `  
  font-style: normal;
  margin-left:6px;
  font-weight: 700!important;
  font-size: 24!important;
  line-height: 23px!important;  
  color: #6B6B6B;

`
);
const PriceTotal = styled(Typography)(
  () => `  
  font-style: normal;
  font-weight: 700!important; 
  line-height: 22px!important; 
  font-size: 16px!important;
  color: #000; 
`
);

const PricefooterTotal = styled(Typography)(
  () => `  
  font-style: normal;
  font-weight: 700!important;
  line-height: 30px!important;
  font-size: 22px!important;
  color: #000; 
`
);

const StyledPrice = styled(Typography)(
  () => `
    font-style: normal;
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 30px!important; 
    display: flex;
    justify-content: space-between; 
    margin-right: 6px;
    color: #6B6B6B!important;
`
);
const StyledContent = styled(Grid)(
  () => ` 
  padding: 10px;
  margin-bottom: 20px;
`
);
const StyledOrderTitle = styled(Typography)(
  () => ` 
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px!important;
    line-height: 22px!important;
    display: flex;
    justify-content: space-between;
    color: #000!important;
    padding: 10px;
`
);

const StyledOrderFooterTitle = styled(Typography)(
  () => ` 
    font-style: normal;
    font-weight: 700 !important;
    font-size: 22px!important;
    line-height: 30px!important;
    display: flex;
    justify-content: space-between;
    color: #000!important;
    padding: 10px;
`
);
const StyledOder = styled(Box)(
  () => `
    background: #FFF;
    @media (min-width: 960px) {
      min-width: 380px;
    }
`
);
const StyledOderSammury = styled(Box)(
  () => `
 
`
);
