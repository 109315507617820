import * as Yup from "yup";
import {
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect
} from "react";
import { Typography, Grid, Button, Checkbox, FormControlLabel, Divider } from "@mui/material";
import {
  RHDropDownSearch,
  RHDateField,
  RHFTextFieldForm,
} from "@components/hook-form";
import {RHFMonthYearInput} from "@components/hook-form";
import { FormProvider } from "@components/hook-form";
import { MethodeType } from "~/types";
// components
import { SnackbarOrigin } from "@mui/material/Snackbar";
import { Box, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//import { GetAllState } from "~/repositories/ddllist.service";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import Toast from "~/components/Notification";
import { ShopContext } from "~/contexts/ShopContext";
import { AuthContext } from "~/contexts/authContext";
import { CheckBox } from "@mui/icons-material";
import { useStaleQuery } from "~/reactQueryHooks/useQueryStaleList";
import { Get as GetAddress } from "~/repositories/customerContact.service";
import { ValidateCC } from "~/repositories/customerOrder.service";
export interface SimpleDialogProps {
  onPayment: (data : any) => void;
  isSubmitting: boolean;
  paymentTxt?: string;
  ponumber?: any;
  invoicePayment?: boolean;
}

export interface State extends SnackbarOrigin {
  open?: boolean;
}

const FormInputList = [
  { name: "name", label: "Cardholder Name" },
  { name: "cc", label: "Card Number" },
  { name: "cvv", label: "CVV" },
  { name: "expdate", label: "MM/YYYY" },
];
type IDefaultValues = Record<string, any>;
const DEFAULT_VALUES: IDefaultValues = {
  name: "",
  cc: "",
  cvv: "",
  //expdate: "",
  ccaddress: "",
  cccity: "",
  ccstate: "",
  cczipcode: "",
};

export default function CartPaymentForm(props: SimpleDialogProps) {
  const { user } = useContext(AuthContext);
  const { products, order, onChangeData, onChangeOrder } =
    useContext(ShopContext);

  const theme = useTheme();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [useBillingAddress, setUseBillingAddress] = useState<boolean>(true)
  const [selectedStates, setSelectedStates] = useState<IDefaultValues>();
  const [errorMessage, seterrorMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { isLoading, data: listStates } = useStaleQuery();

  const selectedProducts: any = useMemo(() => {
    return products?.filter((product) => product.quantity > 0);
  }, [products]);

  const defaultProps = useMemo(() => {
    return {
      options: listStates,
      getOptionLabel: (option: IDefaultValues) => option.name,
    };
  }, [listStates]);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("Card holder name is required"),
    cc: Yup.string()
      .required("Card Number Required")
      .matches(/^[0-9]+$/, "Must Be Only Digits")
      .min(13, "Must Have More Than 13 Digits")
      .max(16, "Must Have Less Than 16 Digits"),
    cvv: Yup.string()
      .required("CVV Required")
      .min(3, "Must Be 3-4 Digits")
      .max(4, "Must Be 3-4 Digits"),
    expdate: Yup.string().min(6, "A valid date is required").required("Exp date is required"),
    ccaddress: Yup.string().when([],{
      is: ()=> useBillingAddress === false,
      then: Yup.string().required("Address is required"),
      otherwise: Yup.string().notRequired()
    }),
    cccity: Yup.string().when([],{
      is: ()=> useBillingAddress === false,
      then: Yup.string().required("City is required"),
      otherwise: Yup.string().notRequired()
    }),
    ccstate: Yup.string().when([],{
      is: ()=> useBillingAddress === false,
      then: Yup.string().required("State is required"),
      otherwise: Yup.string().notRequired()
    }),
    cczipcode: Yup.string().when([],{
      is: ()=> useBillingAddress === false,
      then: Yup.string().min(5,"Zipcode Must Be Exactly 5 Digits").max(5,"Zipcode Must Be Exactly 5 Digits").required("State is required"),
      otherwise: Yup.string().notRequired()
    })
  });

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues: DEFAULT_VALUES,
  });
  const { handleSubmit, setValue, reset, clearErrors } = methods;

  const onSubmit = useCallback(
    async (data: Record<string, any>) => {
      setIsSubmitting(true)
      if(props.ponumber){
        data = {
          ...data,
          ponumber: props.ponumber
        }
      }
      data = {
        ...data,
        useBillingAddress: useBillingAddress,
      }

      if(data.useBillingAddress === true){
        await GetAddress(order?.billto_id.toString()).then(
          (response : any)=>{
            const billingAddress = response.data[0]
            data = {
              ...data,
              ccaddress: billingAddress.address1,
              cccity: billingAddress.city,
              ccstate: billingAddress.state,
              cczipcode: billingAddress.zip,
            }
          },
          (error: any)=>{
            console.log(error)
          }
        )
      }
      const date = data.expdate.split('/');
      const validationData = {
          name: data.name,
          cc: data.cc,
          expirymonth: date[0],
          expiryyear: date[1],
          cvv: data.cvv.toString(),
          ccaddress: data.ccaddress,
          ccstate: data.ccstate,
          cccity: data.cccity,
          cczipcode: data.cczipcode,
          cccountry: "US",
          ccamount: 1,
      }
      await ValidateCC(validationData).then(
        (response: any)=>{
          if(response.data.returnobject === true){
            props.onPayment(data)
          }else{
            seterrorMessage(response.data.message)
            OnOpenToast()
          }
        },(error: any)=>{
          console.log(error.data)
        }
      )
      setIsSubmitting(false)
    },
    [props.ponumber, useBillingAddress]
  );

  const handleClose = () => {
    reset(DEFAULT_VALUES);
  };
  const OnOpenToast = () => {
    setIsOpen(true);
  };

  const OnCloseToast = () => {
    setIsOpen(false);
  };

  const handleSelectStates = (event: IDefaultValues | null) => {
    if (event) {
      setSelectedStates(event);
      setValue("ccstate", event.name);
      clearErrors("ccstate")
    }
    if (event === null) {
      setSelectedStates(undefined);
      setValue("ccstate", "");
    }
  };

  useEffect(()=>{
    if(props.invoicePayment == true){
      setUseBillingAddress(false)
    }
  },[props.invoicePayment])

  return (
    <Box sx={{[theme.breakpoints.down('md')]:{width: "100%"},[theme.breakpoints.up('md')]:{width: "100%"}}}>
      <Toast
        open={isOpen}
        message={errorMessage}
        onClose={OnCloseToast}
        severity={"error"}
      />
      <StyledOrderTitle
        sx={{
          textAlign: "start",
          ml: 5
        }}
      >
        Card Details
      </StyledOrderTitle>
      <FormProvider
        methods={methods as unknown as MethodeType}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing="10px" px={5} pb={5}>
          <>
            {FormInputList.map((field, index) => (
              <>
                {field.name === "expdate" ? (
                <Grid item xs={12} md={6} lg={6}>
                  <RHFMonthYearInput
                    sx={{
                      borderRadius: " 50%",
                    }}
                    name={field.name}
                    key={index}
                    placeholder={field.label}
                  />
                </Grid>
                ) : field.name === "cvv" || field.name === "cc" ? (
                  <>
                  <Grid key={index} item xs={12} md={6} lg={6}>
                    <RHFTextFieldForm
                      type={"number"}
                      sx={{
                        borderRadius: " 50%",
                      }}
                      name={field.name}
                      key={index}
                      placeholder={field.label}
                    />
                  </Grid>
                </>
                ) : (
                  <>
                    <Grid key={index} item xs={12} md={6} lg={6}>
                      <RHFTextFieldForm
                        sx={{
                          borderRadius: " 50%",
                        }}
                        name={field.name}
                        key={index}
                        placeholder={field.label}
                      />
                    </Grid>
                  </>
                )}
              </>
            ))}

            {!useBillingAddress && (
              <>
                  <Grid item xs={12} md={6} lg={6} sx={props.invoicePayment? {} : {[theme.breakpoints.down('md')]:{mt:10},mt:5}}>
                    <RHFTextFieldForm
                      sx={{
                        borderRadius: " 50%",
                      }}
                      name={"ccaddress"}
                      placeholder="Address*"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} sx={props.invoicePayment? {} : {[theme.breakpoints.up('md')]:{mt:5}}}>
                    <RHFTextFieldForm
                      sx={{
                        borderRadius: " 50%",
                      }}
                      name={"cccity"}
                      placeholder="City*"
                    />
                  </Grid>
                  {Boolean(listStates) && (
                      <Grid item xs={12} md={6} lg={6}>
                        <StyledState
                          name="ccstate"
                          label="State*"
                          defaultProps={defaultProps}
                          slected={selectedStates}
                          handleSelection={handleSelectStates}
                          
                        />
                      </Grid>
                    )}
                   <Grid item xs={12} md={6} lg={6}>
                    <RHFTextFieldForm
                      sx={{
                        borderRadius: " 50%",
                      }}
                      type={"number"}
                      name={"cczipcode"}
                      placeholder="Zipcode*"
                    />
                  </Grid> 
              </>
            )}
            {props.invoicePayment !== true? 
              <Grid item xs={12} md={6} lg={6} sx={{mt:5}}>
                <FormControlLabel control={<Checkbox checked={useBillingAddress} onChange={(e:any)=>{setUseBillingAddress(e.target.checked)}}/>} label="Use Billing Address" />
              </Grid>
            :
              <Grid item xs={12} md={6} lg={6} sx={{mt:5}}></Grid>
            }
            <Grid item xs={12} md={6} lg={6} sx={{[theme.breakpoints.up('md')]:{mt:5}}}>
              <CheckoutButton
                type="submit"
                sx={{ p: 1, fontSize: "24px", mt: "10px" }}
                loading={props.isSubmitting || isSubmitting}
              >
                {props.paymentTxt? props.paymentTxt : "Pay and Confirm Order"}
              </CheckoutButton>
            </Grid>
          </>
        </Grid>
      </FormProvider>
    </Box>
  );
}

const StyledState = styled(RHDropDownSearch)(
  () => ` 
  .MuiInputBase-root, .MuiOutlinedInput-root{
    border: 0px solid #ebebeb;
    border-radius: 0px !important;
  }

  min-height:44px;

`
);

const StyledOrderTitle = styled(Typography)(
  () => ` 
    font-style: normal; 
    line-height: 18px !important;
    font-size: 20px;
    color: #000;
    font-weight: 100;
    margin-bottom: 10px;

`
);

const CheckoutButton = styled(LoadingButton)(
  () => `
    background: #1CB7EC;
    border-radius: 0;
    font-size: 18px !important;
    font-weight: 400;
    height: 44px;
    line-height: 22px;
    padding: 0;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    gap: 5px;

    :hover {
      background: #5569ff;
      color: #FFF;
    }

`
);