import React, {FC, useCallback} from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    styled,
    Box,
    Tab,
    Tabs,
    Typography,
    IconButton,
    TextField,
    useTheme,
    Divider,
} from "@mui/material";
import ShippingFrom from "./ShippingForm";
import BillingFrom from "./BillingForm";
import PatientForm from "./PatientForm";

interface IUserFormDialog {
    onClose: () => void;
    setShipping: (id : any) => void;
    setBilling: (id : any) => void;
    refetch: (type : number) => void;
    addressList: any[];
    open: boolean;
    typeIndex: number;
    shippingAddress?: any;
}

const CloseButton = styled("div")(
    () => `
      && {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #000;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 25px;
      }
  `
  );

const AddressBook: FC<IUserFormDialog> = ({
    onClose,
    refetch,
    addressList,
    open,
    typeIndex,
    setShipping,
    setBilling,
    shippingAddress,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const addressTypes = [ "Shipping", "Billing", "Patient" ];

    const handleRefetch = useCallback(()=>{
        refetch(typeIndex)
    },[typeIndex])

    return(
        <>
        <Dialog
            fullScreen={fullScreen}
            open={open}
            maxWidth="sm"
            fullWidth={true}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            sx={{ height: "800px"}}
        >
            <Box sx={{position: "absolute",top: 0, right: 0, p: 2}}>
                <CloseButton onClick={onClose}>x</CloseButton>
            </Box>
            <DialogTitle id="responsive-dialog-title">
                Select a {addressTypes[typeIndex]} Address
            </DialogTitle>
            <Divider/>
            <DialogContent sx={{ height: "800px" }}>
                {typeIndex == 0? 
                
                <ShippingFrom
                    addressList={addressList}
                    setShipping={setShipping}
                    onClose={onClose}
                    refetch={refetch}
                />

                :typeIndex == 1?

                <BillingFrom
                    addressList={addressList}
                    setBilling={setBilling}
                    onClose={onClose}
                    refetch={refetch}
                    shippingAddress={shippingAddress}
                />

                :
                
                <PatientForm
                    addressList={addressList}
                    setShipping={setShipping}
                    onClose={onClose}
                    refetch={refetch}
                />
                
                }
            </DialogContent>
        </Dialog>
        </>
    )

}

export default AddressBook;