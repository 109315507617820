import React, {FC, useState, useCallback, useMemo, useEffect} from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { 
  Typography,
  styled,
  Box,
  Grid,
  Stack,
  Checkbox,
  Button,
  IconButton,
  TextField,
  Tooltip,
 } from "@mui/material";
 import AddCircleIcon from '@mui/icons-material/AddCircle';
 import PersonIcon from '@mui/icons-material/Person';
 import { useForm } from "react-hook-form";
 import { CreateShipping, Get } from "~/repositories/customerContact.service";
 import {
  RHDateField,
  RHDropDownSearch,
  RHFTextFieldForm,
  RHFPhoneInput,
} from "@components/hook-form";
 import { FormProvider } from "@components/hook-form";
 import { MethodeType } from "~/types";
 import { LoadingButton } from "@mui/lab";
 import Toast from "~/components/Notification";
 import { useStaleQuery } from "~/reactQueryHooks/useQueryStaleList";

interface IShippingForm {
  addressList: any[];
  setShipping: (id : any) => void;
  onClose: () => void;
  refetch: (type : number) => void;
}

const FormInputList = [
  { name: "name", label: "Name" },
  { name: "location", label: "Location" },
  { name: "address1", label: "Address 1" },
  { name: "address2", label: "Address 2" },
  { name: "city", label: "City" },
  { name: "state", label: "State" },
  { name: "zip", label: "ZIP" },
  { name: "phone", label: "Phone" },
  //{ name: "isdefault", label: "Is Default" },
];

type IDefaultValues = Record<string, any>;
const DEFAULT_VALUES: IDefaultValues = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  phone: "",
  comments: "",
  zip: "",
  tags: "",
  name: "",
  ispatient: false,
  isdefault: false,
};

const ShippingForm: FC<IShippingForm> = ({
  addressList,
  setShipping,
  onClose,
  refetch,
}) => {

  
  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>();
  const [inputSearch, setInputSearch] = useState("");
  const [selectedStates, setSelectedStates] = useState<IDefaultValues>();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<any>("");

  const { isLoading, data: listStates } = useStaleQuery();

  const filteredAddress = useMemo(()=>{
    if(addressList)
      return addressList.filter(item => 
        item?.name?.toLowerCase().indexOf(inputSearch.toLowerCase()) > -1 ||
        item?.address1?.toLowerCase().indexOf(inputSearch.toLowerCase()) > -1 ||
        item?.address2?.toLowerCase().indexOf(inputSearch.toLowerCase()) > -1 ||
        item?.city?.toLowerCase().indexOf(inputSearch.toLowerCase()) > -1 || 
        item?.state?.toLowerCase().indexOf(inputSearch.toLowerCase()) > -1 || 
        item?.zip?.toString().indexOf(inputSearch.toLowerCase()) > -1);
    else
        return []
  },[addressList, inputSearch])

  const Schema = Yup.object().shape({
    name: Yup.string().required("Patient Last Name required"),
    address1: Yup.string().required("Address 1 required"),
    city: Yup.string().required("City required"),
    state: Yup.string().required("State required"),
    phone: Yup.string()
      .required("Phone Required")
      .test("phone", "Phone must be 10 digit", (value) =>
        !value ? false : value.length === 14
      ),
    zip: Yup.string()
      .required("Zip required ")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
  });

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues: DEFAULT_VALUES,
  });

  const { handleSubmit, setValue, reset } = methods;

  const onSubmit = useCallback(
    async (data: Record<string, any>) => {
    data = {
      ...data,
      isdefault: false,
      contacttypeid: 3,
      }
     await CreateShipping(data).then(
        (response)=>{
          refetch(0)
          Get(response.data.returnobject.id).then(
            (response)=>{
              setShipping(response.data[0]);
              handleOpen("Address Created", "success")
              onClose()
            },
            (error)=>{
              handleOpen(error.response.data.message, "error")
            }
          )
        },
        (error)=>{
          console.log(error)
          handleOpen(error.response.data.message, "error")
        }
      )
    }
  ,[])

  const handleSetShipping = useCallback(()=>{
    setShipping(selected);
    onClose()
  },[selected])

  const handleSelectShipping = useCallback((item : any)=>{
    setSelected(item)
  },[selected])

  const handleFormChange = useCallback(()=>{
    setAddOpen(!isAddOpen)
  },[isAddOpen])

  const defaultProps = useMemo(() => {
    return {
      options: listStates,
      getOptionLabel: (option: IDefaultValues) => option.name,
    };
  }, [listStates]);

  const handleSelectStates = (event: IDefaultValues | null) => {
    if (event) {
      setSelectedStates(event);
      setValue("state", event.name);
    }
    if (event === null) {
      setSelectedStates(undefined);
      setValue("state", "");
    }
  };

  const handleSelectDefault = (event: IDefaultValues | null) => {
    if(event)
      setValue("isdefault", event.target.checked)
    else
      setValue("isdefault", false)
  };

  const handleOpen = useCallback((msg : string, severity : any) =>{
    setMessage(msg)
    setIsOpen(true)
    setSeverity(severity)
  }, [])

  const handleClose = useCallback(() => {
    setMessage("")
    setIsOpen(false);
    setSeverity("")
  }, []);

  return(
    <Box>
      <Toast
        open={isOpen}
        message={message}
        onClose={handleClose}
        severity={severity}
      />
      <Grid
        sx={{p: "30px 0 10px"}}
        container 
        spacing={2}
      >
      <Grid item xs={8}>
        <StyledSearch
           onChange={(e) => {
            setInputSearch(e.target.value);
          }} 
          value={inputSearch}
          size="small"
          label="Search Addresses"
          sx={{
            width: "100%",
            background: "#fff",
            height: "45px",
            display: "flex",
            "& .Mui-focused .MuiIconButton-root": {
              color: "primary.main",
            },
            // alignItems: "center",
          }}
          />
        </Grid>
        <Grid item xs={4}>
          {selected &&(
            <SaveButton onClick={()=>{handleSetShipping()}} variant="contained">
              Use Address
            </SaveButton>
          )}
        </Grid>
      </Grid>

      <Box sx={{display:"flex"}}>
        {!isAddOpen? 
          <IconButton
            onClick={()=>{handleFormChange()}}
          >
            <AddCircleIcon/>
            <Typography sx={{ml:"5px"}}>
              Add New Address
            </Typography>
          </IconButton>
        :
        <IconButton
        onClick={()=>{handleFormChange()}}
        >
          <PersonIcon/>
          <Typography sx={{ml:"5px"}}>
            Back to Address List
          </Typography>
        </IconButton>
        }
      </Box>

      {isAddOpen? 
        <FormProvider
          methods={methods as unknown as MethodeType}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing="10px" sx={{mt: "15px"}}>
          <>
          {FormInputList.map((field, index) => (
              <>
                {field.name === "state" ? (
                  <>
                    {Boolean(listStates) && (
                      <Grid item xs={12} md={6} lg={6}>
                        <StyledState
                          name="state"
                          label="State*"
                          defaultProps={defaultProps}
                          slected={selectedStates}
                          handleSelection={handleSelectStates}
                          sx={{
                            maxHeight: "0px",
                          }}
                        />
                      </Grid>
                    )} 
                  </>
                ) : field.name === "phone" ? (
                  <Grid key={index} item xs={12} md={6} lg={6}>
                    <RHFPhoneInput name={field.name} key={index} />
                  </Grid>
                ) : field.name === "isdefault" ? (
                  <Grid display={"flex"} sx={{height:"20px", mt: "10px"}} key={index} item xs={12} md={6} lg={6}>
                    <Checkbox
                      disableRipple
                      name={"isdefault"}
                      onChange={handleSelectDefault}
                      sx={{}}
                    />
                    <Typography>
                      {field.label}
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid key={index} item xs={12} md={6} lg={6}>
                      <RHFTextFieldForm
                        sx={{
                          borderRadius: " 50%",
                        }}
                        name={field.name}
                        key={index}
                        placeholder={field.label}
                      />
                    </Grid>
                  </>
                )}
              </>
            ))}
            </>
            <Grid item xs={12} md={12} lg={12}>
              <Box
                display={"flex"}
                justifyContent="end"
                sx={{
                  gap: "10px",
                  padding: " 30px 0",
                }}
              >
                <SaveButton type="submit" variant="contained">
                  Save
                </SaveButton>
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      :
      <>
        {filteredAddress?.map((shipping: any) => {
          return(
            <Box sx={{display:"flex"}}>
              <Checkbox
                checked={shipping == selected}
                onChange={()=>handleSelectShipping(shipping)}
              />
              <Name>
                {shipping.name} &nbsp;
              </Name>
              <Address>
                {shipping.address1 + " " + shipping.address2 + " " + shipping.city + ", " + shipping.state + ", " + shipping.zip}
              </Address>
            </Box>
          );
        })}
      </>
      }
    </Box>
  )
}

const StyledState = styled(RHDropDownSearch)(
  () => ` 
  .MuiInputBase-root, .MuiOutlinedInput-root{
    border-radius: 0px !important;
  }

`
);

const Name = styled(Typography)(
  () => `
    color: #000; 
    font-weight: 700;
    font-size: 15px !important;
    letter-spacing: 1.5px; 
    margin-top: 10px;
`
);

const Address = styled(Typography)(
  () => `
    color: #000; 
    font-weight: 500;
    font-size: 15px !important;
    letter-spacing: 1.5px; 
    margin-top: 10px;
`
);

const SaveButton = styled(Button)(
  ({}) => `
  && {
    .disabled {
      background: grey;
      opacity: 0.5;
    }  
  color: #FFF; 
  font-size: 15px;
  border-radius: 0;
  display: inline-block;
  float: right;
  background: #12a7da;
  padding: 10px 10px;
  border-radius: 5px;
  }
`
);

const StyledSearch = styled(TextField)(
  () => `
  border-color: #000 !important;
  fieldset{
    border-color: #000 !important;
    border: 1px solid #000;
    border-radius: 25px;
  }
  & .MuiOutlinedInput-root {
   padding-right: 13px !important;
}
`
);


export default ShippingForm