import { ReactNode, memo, useState, useEffect, useCallback } from "react";
import { styled, Box, useTheme, Checkbox } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const ColapsableSubPage = ({
  title,
  children,
  setExpanded,
  Click,
}: {
  title: ReactNode;
  children: ReactNode;
  setExpanded?:  boolean;
  Click?: ()=>void;
}) => {
  const theme = useTheme();
  const [isExpanded, setisExpanded] = useState<boolean | undefined>(false);


  useEffect(()=>{
      if(setExpanded != undefined)
        setisExpanded(setExpanded)
  },[setExpanded,setisExpanded,isExpanded])

  return (
    <StyledAccordion expanded={isExpanded}>
      <AccordionSummary
        expandIcon={
          <KeyboardArrowRightIcon
            // sx={{
            //   color: "#35b7ec",
            // }}
            onClick={() => setisExpanded(!isExpanded)}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ minHeight: "30px !important" }}
        onClick={(e) => {Click? Click() : e.stopPropagation()}}
        onChange={(e) => e.stopPropagation()}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          onClick={() => setisExpanded(!isExpanded)}
        >
          <Box display="flex" alignItems="center" gap="10px">
            {title}
          </Box>
          {/* <AccordionActions></AccordionActions> */}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0px 18px 0px",
          [theme.breakpoints.down("lg")]: {
            padding: 0,
          },
        }}
      >
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(Accordion)(
  () => `
    && {
      border-radius: 0;
      .MuiAccordionSummary-content {
        margin: 0;
      }
      .MuiAccordionSummary-root {
        padding: 0;
      }
    }
`
);

export default memo(ColapsableSubPage);
